import { SectionComponentType } from '../constants/sections'
import CasesSection, { ICasesSectionData } from '../components/page-sections/cases-section'
import DefaultSection, { IDefaultSectionData } from '../components/page-sections/default-section'
import ListSection, { IListSectionData } from '../components/page-sections/list-section'
import QuotesSection, { IQuotesSectionData } from '../components/page-sections/quotes-section'
import TabsSection, { ITabsSectionData } from '../components/page-sections/tabs-section'
import BasicCardsSection, { IBasicCardsSectionData } from '../components/page-sections/cards-section/basic-cards-section'
import TextCardsSection, { ITextCardsSectionData } from '../components/page-sections/cards-section/text-cards-section'
import ImageCardsSection, { IImageCardsSectionData } from '../components/page-sections/cards-section/image-cards-section'
import GridSection, { IGridSectionData } from '../components/page-sections/grid-section'
import ImagesCarouselSection, { IImagesCarouselSectionData } from '../components/page-sections/carousel-section/images-carousel-section'
import ReviewsCarouselSection, { IReviewsCarouselSectionData } from '../components/page-sections/carousel-section/reviews-carousel-section'
import LicenseCardsSection, { ILicenseCardsSectionData } from '../components/page-sections/cards-section/license-cards-section'
import ReviewCardsSection, { IReviewCardsSectionData } from '../components/page-sections/cards-section/review-cards-section'
import ImageSection, { IImageSectionData } from '../components/page-sections/image-section'
import CasesCarouselSection, { ICasesCarouselSectionData } from '../components/page-sections/carousel-section/cases-carousel-section'
import ButtonsSection, { IButtonsSectionData } from '../components/page-sections/buttons-section'
import IconListSection, { IIconListSectionData } from '../components/page-sections/icon-list-section'
import CasesSelectionSection, { ICasesSelectionSectionData } from '../components/page-sections/cases-selection-section/CasesSelectionSection'
import WysiwygSection, { IWysiwygSectionData } from '../components/page-sections/wysiwyg-section/WysiwygSection'
import StepsSection, { IStepsSectionData } from '../components/page-sections/steps-section/StepsSection'
import SocialsSection, { ISocialsSectionData } from '../components/page-sections/socials-section/SocialsSection'
import FaqSection, { IFaqSectionData } from '../components/page-sections/faq-section/FaqSection'
import BlogCarouselSection, { IBlogCarouselSectionData } from '../components/page-sections/carousel-section/blog-section/BlogSection'
import LicensesCarouselSection, { ILicensesCarouselSectionData } from '../components/page-sections/carousel-section/licenses-carousel-section/LicensesCarouselSection'
import BreadCrumbsSection, { IBreadCrumbsSectionData } from '../components/page-sections/bread-crumbs-section/BreadCrumbsSection'
import TabsListSection, { ITabsListSectionData } from '../components/page-sections/tabs-list-section/TabsListSection'
import TextCardsCarouselSection, { ITextCardsCarouselSectionData } from '../components/page-sections/carousel-section/text-cards-carousel-section copy/TextCardsCarouselSection'
import TableSection, { ITableSectionData } from '../components/page-sections/table-section/TableSection'
import ActionCardsSection, { IActionCardsSectionData } from '../components/page-sections/cards-section/action-cards-section/ActionCardsSection'
import PriceTagsSection, { IPriceTagsSectionData } from '../components/page-sections/price-tags-section/PriceTagsSection'
import SimpleCardsSection, { ISimpleCardsSectionData } from '../components/page-sections/cards-section/simple-cards-section/SimpleCardsSection'
import PriceCardsSection, { IPriceCardsSectionData } from '../components/page-sections/cards-section/price-cards-section/PriceCardsSection'
import HoveringSection, { IHoveringSectionData } from '../components/page-sections/hovering-section/HoveringSection'
import PriceLabelCardsSection, { IPriceLabelCardsSectionData } from '../components/page-sections/cards-section/price-label-cards-section/PriceLabelCardsSection'

export type ISectionData =
  IDefaultSectionData | IBasicCardsSectionData | ICasesSectionData |
  IListSectionData | IQuotesSectionData |
  IImagesCarouselSectionData | ITabsSectionData | ITextCardsSectionData | IImageCardsSectionData |
  IGridSectionData | IReviewsCarouselSectionData | ILicensesCarouselSectionData | ILicenseCardsSectionData |
  IReviewCardsSectionData | IImageSectionData |
  ICasesCarouselSectionData | IButtonsSectionData | IIconListSectionData | ICasesCarouselSectionData |
  ICasesSelectionSectionData | IWysiwygSectionData | IStepsSectionData | ISocialsSectionData |
  IFaqSectionData | IBlogCarouselSectionData | IBreadCrumbsSectionData | ITabsListSectionData |
  ITextCardsCarouselSectionData | ITableSectionData | IActionCardsSectionData | IPriceTagsSectionData |
  ISimpleCardsSectionData | IPriceCardsSectionData | IHoveringSectionData | IPriceLabelCardsSectionData

const renderSection = (sectionData: ISectionData) => {
  const sectionType = sectionData.strapi_component

  try {
    switch (sectionType) {
      case SectionComponentType.DEFAULT_SECTION:
        return <DefaultSection data={sectionData as IDefaultSectionData} />

      case SectionComponentType.BASIC_CARDS_SECTION:
        return <BasicCardsSection data={sectionData as IBasicCardsSectionData} />

      case SectionComponentType.TEXT_CARDS_SECTION:
        return <TextCardsSection data={sectionData as ITextCardsSectionData} />

      case SectionComponentType.IMAGE_CARDS_SECTION:
        return <ImageCardsSection data={sectionData as IImageCardsSectionData} />

      case SectionComponentType.LICENSE_CARDS_SECTION:
        return <LicenseCardsSection data={sectionData as ILicenseCardsSectionData} />

      case SectionComponentType.REVIEW_CARDS_SECTION:
        return <ReviewCardsSection data={sectionData as IReviewCardsSectionData} />

      case SectionComponentType.CASES_SECTION:
        return <CasesSection data={sectionData as ICasesSectionData} />

      case SectionComponentType.LIST_SECTION:
        return <ListSection data={sectionData as IListSectionData} />

      case SectionComponentType.QUOTE_SECTION:
        return <QuotesSection data={sectionData as IQuotesSectionData} />

      case SectionComponentType.IMAGES_CAROUSEL_SECTION:
        return <ImagesCarouselSection data={sectionData as IImagesCarouselSectionData} />

      case SectionComponentType.REVIEWS_CAROUSEL_SECTION:
        return <ReviewsCarouselSection data={sectionData as IReviewsCarouselSectionData} />

      case SectionComponentType.CASES_CAROUSEL_SECTION:
        return <CasesCarouselSection data={sectionData as ICasesCarouselSectionData} />

      case SectionComponentType.LICENSES_CAROUSEL_SECTION:
        return <LicensesCarouselSection data={sectionData as ILicensesCarouselSectionData} />

      case SectionComponentType.TABS_LAYOUT:
        return <TabsSection data={sectionData as ITabsSectionData} />

      case SectionComponentType.GRID_LAYOUT:
        return <GridSection data={sectionData as IGridSectionData} />

      case SectionComponentType.IMAGE_SECTION:
        return <ImageSection data={sectionData as IImageSectionData} />

      case SectionComponentType.BUTTONS_SECTION:
        return <ButtonsSection data={sectionData as IButtonsSectionData} />

      case SectionComponentType.ICON_LIST_SECTION:
        return <IconListSection data={sectionData as IIconListSectionData} />

      case SectionComponentType.CASES_SELECTION_SECTION:
        return <CasesSelectionSection data={sectionData as ICasesSelectionSectionData} />

      case SectionComponentType.WYSIWYG_SECTION:
        return <WysiwygSection data={sectionData as IWysiwygSectionData} />

      case SectionComponentType.STEPS_SECTION:
        return <StepsSection data={sectionData as IStepsSectionData} />

      case SectionComponentType.SOCIALS_SECTION:
        return <SocialsSection data={sectionData as ISocialsSectionData} />

      case SectionComponentType.FAQ_SECTION:
        return <FaqSection data={sectionData as IFaqSectionData} />

      case SectionComponentType.BLOG_SECTION:
        return <BlogCarouselSection data={sectionData as IBlogCarouselSectionData} />

      case SectionComponentType.BREAD_CRUMBS_SECTION:
        return <BreadCrumbsSection data={sectionData as IBreadCrumbsSectionData} />

      case SectionComponentType.TABS_LIST_SECTION:
        return <TabsListSection data={sectionData as ITabsListSectionData} />

      case SectionComponentType.TEXT_CARDS_CAROUSEL_SECTION:
        return <TextCardsCarouselSection data={sectionData as ITextCardsCarouselSectionData} />

      case SectionComponentType.ACTION_CARDS_SECTION:
        return <ActionCardsSection data={sectionData as IActionCardsSectionData} />

      case SectionComponentType.TABLE_SECTION:
        return <TableSection data={sectionData as ITableSectionData} />

      case SectionComponentType.PRICE_TAGS_SECTION:
        return <PriceTagsSection data={sectionData as IPriceTagsSectionData} />

      case SectionComponentType.PRICE_CARDS_SECTION:
        return <PriceCardsSection data={sectionData as IPriceCardsSectionData} />

      case SectionComponentType.SIMPLE_CARDS_SECTION:
        return <SimpleCardsSection data={sectionData as ISimpleCardsSectionData} />

      case SectionComponentType.HOVERING_SECTION:
        return <HoveringSection data={sectionData as IHoveringSectionData} />

      case SectionComponentType.PRICE_LABEL_CARDS_SECTION:
        return <PriceLabelCardsSection data={sectionData as IPriceLabelCardsSectionData} />

      default:
        throw new Error(`Unexpected strapi_component type: ${sectionType}`)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return null
  }
}

export default renderSection
