import { RefObject, useEffect, useRef } from 'react'

const useIsInView = (ref: RefObject<HTMLElement>, callback: () => void) => {
  const observerRef = useRef<IntersectionObserver>()

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([ entry ]) => {
        if (entry.isIntersecting) {
          callback()
        }
      },
      { rootMargin: '400px', threshold: 0 },
    )
  }, [ callback ])

  useEffect(() => {
    const observer = observerRef.current
    if (!observer) {
      return
    }

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => observer.disconnect()
  }, [ ref ])
}

export default useIsInView
