import { IStrapiActionCard, IStrapiActionCardsEffect } from '../../../../types/strapi'
import CardsSection, { ICardsSectionData } from '..'
import AccordionCard from '../../../section-card/accordion-card/AccordionCard'
import FlipCard from '../../../section-card/flip-card/FlipCard'
import RoleCard from '../../../section-card/role-card/RoleCard'
import FullAccordionCard from '../../../section-card/accordion-card/FullAccordionCard'
import SlideDownCard from '../../../section-card/slide-down-card/SlideDownCard'
import { styled } from '@mui/material'
import { useSectionsContext } from '../../../../hooks/useSectionsContext'
import { useLayoutEffect } from 'react'
import { useIsMobile } from '../../../../hooks/useIsMobile'

export interface IActionCardsSectionData extends ICardsSectionData {
  actionCards: Array<IStrapiActionCard>
  effect: IStrapiActionCardsEffect
}

export interface IActionCardsSectionProps {
  data: IActionCardsSectionData
}

const ActionCardsSection = (props: IActionCardsSectionProps) => {
  const { actionCards, effect } = props.data

  const isMobile = useIsMobile('lg')

  const castActionCardComponent = () => {
    switch (effect) {
      case IStrapiActionCardsEffect.ACCORDION:
        return AccordionCard

      case IStrapiActionCardsEffect.FLIP:
        return FlipCard

      case IStrapiActionCardsEffect.ROLE_ACCORDION:
        return RoleCard

      case IStrapiActionCardsEffect.SLIDE_DOWN:
        return SlideDownCard

      case IStrapiActionCardsEffect.FULL_ACCORDION:
        return FullAccordionCard

      default:
        // eslint-disable-next-line no-console
        console.log('Unknown action card effect:', effect)
        return AccordionCard
    }
  }

  return (
    <>
      {effect === IStrapiActionCardsEffect.SLIDE_DOWN && !isMobile && <SlideDownSectionStyles data={props.data} />}
      <CardsSection CardComponent={castActionCardComponent()} data={props.data} cards={actionCards} />
    </>
  )
}

export default ActionCardsSection

const SlideDownSectionStyles = styled('div', { shouldForwardProp: prop => prop !== 'data' })<{ data: IActionCardsSectionData}>(({ data }) => {
  const { setNextSectionModifiers } = useSectionsContext()

  useLayoutEffect(() => {
    setNextSectionModifiers({ extraTopPadding: 70 }, data)
  }, [ data, setNextSectionModifiers ])

  return {
    '& ~ .MuiGrid-container': {
      marginTop: -150,
      transform: 'translateY(150px)',
    },
  }
})
