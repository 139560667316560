import { styled } from '@mui/material/styles'

export const RowNumberWrapper = styled('div')(({ theme }) => ({
  'position': 'relative',
  'display': 'inline-flex',
  'justifyContent': 'center',
  'alignItems': 'center',
  'backgroundColor': theme.palette.brand.lightGray,
  'borderColor': theme.palette.text.primary,
  'border': '1px dashed',
  'borderRadius': '50%',
  'width': 120,
  'height': 120,

  '& .MuiTypography-root': {
    'fontSize': 56,

    'background': `linear-gradient(135deg, ${theme.palette.primary.light} 15.43%, ${theme.palette.primary.dark} 84.56%)`,
    'backgroundClip': 'text',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  [theme.breakpoints.down('lg')]: {
    'width': 100,
    'height': 100,

    '& .MuiTypography-root': {
      fontSize: 46,
    },
  },

  [theme.breakpoints.down('md')]: {
    'width': 80,
    'height': 80,

    '& .MuiTypography-root': {
      fontSize: 38,
    },
  },

  [theme.breakpoints.down('sm')]: {
    'width': 72,
    'height': 72,

    '& .MuiTypography-root': {
      fontSize: 32,
    },
  },
}))

export const RowLine = styled('div')(({ theme }) => ({
  flexGrow: 1,
  borderColor: theme.palette.text.primary,
  borderTop: '1px dashed',
  margin: '0 1px',
}))

export const VerticalLine = styled('div')(({ theme }) => ({
  position: 'absolute',
  flexGrow: 1,
  borderColor: theme.palette.text.primary,
  borderLeft: '1px dashed',
  height: '130%',
  top: '100%',
  zIndex: -1,
}))
