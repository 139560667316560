import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { IStrapiImage, IStrapiImagePosition, IStrapiImageSize, IStrapiImageMobileBehavior, IStrapiSection, IStrapiTitle } from '../../../types/strapi'
import Image from '../../image'
import getImageGridSize from '../../../utils/getImageGridSize'
import useIsLazySection from '../../../hooks/useIsLazySection'
import renderSection from '../../../utils/renderSection'
import { ImageWrapper } from './DefaultSection.styled'
import { useIsMobile } from '../../../hooks/useIsMobile'
import BlockLayout from '../../../layouts/block-layout/BlockLayout'
import Title from '../../title/Title'
import { useSectionsContext } from '../../../hooks/useSectionsContext'

export interface IDefaultSectionData extends IStrapiSection {
  image?: IStrapiImage
  imagePosition?: IStrapiImagePosition
  imageSize?: IStrapiImageSize
  title?: IStrapiTitle
  description?: string
  contentId?: string
  mobileBehavior?: IStrapiImageMobileBehavior
}

export interface IDefaultSectionProps extends React.PropsWithChildren {
  data: IDefaultSectionData
}

const DefaultSection = ({ data, children }: IDefaultSectionProps) => {
  const {
    image,
    imagePosition,
    imageSize: imageSizeUnsafe,
    title,
    description,
    contentId,
    mobileBehavior,
  } = data

  const isLazy = useIsLazySection()
  const isMobile = useIsMobile('md')

  const { sections } = useSectionsContext()
  const contentSections = sections.filter(section => contentId && section.options.anchor === contentId)

  const shouldHideImage = isMobile && mobileBehavior === IStrapiImageMobileBehavior.HIDE_IMAGE
  const desktopOrder = imagePosition === IStrapiImagePosition.LEFT ? 0 : 1
  const mobileOrder = mobileBehavior === IStrapiImageMobileBehavior.IMAGE_FIRST ? 0 : 1

  const imageSize = imageSizeUnsafe || IStrapiImageSize.NORMAL

  return (
    <Grid container spacing={15}>
      {image && !shouldHideImage && (
        <Grid item {...getImageGridSize(imageSize)} order={isMobile ? mobileOrder : desktopOrder}>
          <ImageWrapper imageSize={imageSize}>
            <Image image={image} isLazy={isLazy} />
          </ImageWrapper>
        </Grid>
      )}

      <Grid item md sm={12}>
        <Box display='flex' flexDirection='column' justifyContent='center' height='100%'>
          <Title title={title} hasSpacing={Boolean(description)} />

          {description && (
            <Typography fontSize={20} color='text.secondary'>
              {description}
            </Typography>
          )}

          {contentSections.map((section, index) => (
            <BlockLayout key={`${section.options.anchor}${index}`} options={section.options}>
              {renderSection(section)}
            </BlockLayout>
          ))}

          {children}
        </Box>
      </Grid>
    </Grid>
  )
}

export default DefaultSection
