import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Link from '../link'
import { styled, useTheme } from '@mui/material/styles'
import { IStrapiBackground, IStrapiBaseLink, IStrapiPadding, IStrapiSectionBackground } from '../../types/strapi'
import transformStrapiAlignment from '../../utils/transformStrapiAlignment'
import transformStrapiLink from '../../utils/transformStrapiLink'
import BackgroundImage from '../background-image/BackgroundImage'
import { useCardStyleContext } from '../../hooks/useCardStyleContext'

export interface ICardWrapperProps {
  background?: IStrapiBackground
  link?: IStrapiBaseLink
  children: React.ReactNode
}

export const CardWrapper = React.forwardRef((props: ICardWrapperProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { background, link, children } = props

  const theme = useTheme()

  const linkProps = link ? { component: Link, ...transformStrapiLink(link) } : {}

  const { rounded, shadow, hoverable, alignment, backgroundColor: commonBgColor, background: commonBackground, cardPadding } = useCardStyleContext()

  const alignItems = transformStrapiAlignment(alignment)

  const bgColor = background?.color || commonBgColor || undefined

  const bgImage: Partial<IStrapiBackground & IStrapiSectionBackground> | undefined = background?.image ? background : commonBackground

  // TODO
  const isCardWithoutPadding = !bgColor && !shadow && !hoverable && !rounded && !cardPadding

  const isDarkCard = Boolean(bgColor && theme.palette.getContrastText(bgColor) === theme.palette.common.white)

  return (
    <CardStyled
      ref={ref}
      isDarkCard={isDarkCard}
      sx={{
        height: '100%',
        borderRadius: rounded ? '8px' : 0,
        boxShadow: shadow ? 2 : 0,
        ...(bgColor && { backgroundColor: bgColor }),
        ...(hoverable && {
          '&:hover': {
            boxShadow: theme.shadows[shadow ? 5 : 3],
          },
        }),
        position: 'relative',
      }}
    >
      {bgImage && (
        <BackgroundImage {...bgImage} />
      )}

      <Box
        {...linkProps}
        sx={{
          position: 'relative', // need for fix zIndex
          display: 'flex',
          flexDirection: 'column',
          alignItems,
          boxSizing: 'border-box',
          height: '100%',
          ...(isCardWithoutPadding ? { p: 0 } : castCardPadding(cardPadding)),
          zIndex: 2,
        }}
      >
        {children}
      </Box>
    </CardStyled>
  )
})

CardWrapper.displayName = 'CardWrapper'

export const CardStyled = styled(Card, { shouldForwardProp: prop => prop !== 'isDarkCard' })<{isDarkCard: boolean}>(({ theme, isDarkCard = false }) => ({
  ...(isDarkCard && {
    '& .MuiAccordionSummary-expandIconWrapper svg': {
      color: theme.palette.brand.black60,
    },

    '& .MuiTypography-root *': {
      color: theme.palette.common.white,
    },
  }),
}))

export const CardContent = styled(Box)(() => {
  const { alignment } = useCardStyleContext()

  return {
    alignItems: transformStrapiAlignment(alignment),
    textAlign: alignment,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxSizing: 'border-box',
  }
})

export const castCardPadding = (padding?: IStrapiPadding) => {
  switch (padding) {
    case IStrapiPadding.OFF:
      return { px: { xs: 0, md: 0 }, py: { xs: 0, md: 0 } }

    case IStrapiPadding.SMALL:
      return { px: { xs: 3, md: 3 }, py: { xs: 3, md: 3 } }

    case IStrapiPadding.NORMAL:
      return { px: { xs: 6, md: 6 }, py: { xs: 8, md: 8 } }

    case IStrapiPadding.LARGE:
      return { px: { xs: 6, md: 9 }, py: { xs: 9, md: 12 } }

    case IStrapiPadding.EXTRA_LARGE:
      return { px: { xs: 8, md: 12 }, py: { xs: 10, md: 16 } }

    default:
      // eslint-disable-next-line no-console
      console.log('Unknown card padding prop', padding)
      return { px: { xs: 6, md: 6 }, py: { xs: 8, md: 8 } }
  }
}
