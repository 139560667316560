import { Stack, SxProps, Typography } from '@mui/material'
import { IStrapiLocation } from '../../types/strapi'
import Image from '../image'

export interface ICasesLocationProps extends IStrapiLocation {
  sx?: SxProps
}

export const CasesLocation = (props: ICasesLocationProps) => {
  const { icon, name, ...rest } = props

  if (!icon || !name) {
    return null
  }

  const parsedIconWidth = icon.width ? parseInt(icon.width) : 'auto'

  return (
    <Stack direction='row' alignItems='center' {...rest}>
      <Image image={icon} sx={{ width: parsedIconWidth, flexShrink: 0 }} isLazy />
      <Typography ml={3} variant='body2' fontWeight='inherit'>{name}</Typography>
    </Stack>
  )
}

export default CasesLocation
