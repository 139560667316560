import { styled } from '@mui/material'

export const SectionWrapper = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  top: 0,
  left: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  maxWidth: '100%',
  background: theme.palette.background.default,
  borderRadius: 5,
  overflow: 'hidden',
  boxShadow: '0px 40px 80px 0px rgba(0, 0, 0, 0.25)',
  zIndex: 1,

  [theme.breakpoints.down('md')]: {
    borderRadius: 0,
  },
}))
