import { styled } from '@mui/material/styles'

export const StepArrow = styled('div')(({ theme }) => ({
  'alignSelf': 'center',
  'flexGrow': 1,
  'borderBottom': '1px solid',
  'minWidth': 50,
  'position': 'relative',
  'borderColor': theme.palette.primary.main,
  'filter': `drop-shadow(2px 2px 10px ${theme.palette.brand.black60})`,

  '&:before': {
    position: 'absolute',
    content: '""',
    right: 0,
    top: -3,
    width: 6,
    height: 6,
    transform: 'rotate(-45deg)',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderRightColor: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.dark,
  },
}))

export const RowWrapArrowWrapper = styled('div')(({ theme }) => ({
  'height': 120,
  'position': 'relative',

  '& div:first-of-type': {
    position: 'absolute',
    content: '""',
    top: 0,
    right: 0,
    width: '50%',
    height: '50%',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderRightColor: theme.palette.primary.dark,
    borderBottomColor: theme.palette.primary.main,
    borderRadius: '0 0 98% 0',
    filter: `drop-shadow(2px 2px 10px ${theme.palette.brand.black60})`,
  },

  '& div:nth-of-type(2)': {
    position: 'absolute',
    content: '""',
    left: 0,
    bottom: 0,
    width: '50%',
    height: 'calc(50% - 1px)',
    borderLeft: '1px solid',
    borderTop: '1px solid',
    borderTopColor: theme.palette.primary.main,
    borderLeftColor: theme.palette.primary.main,
    borderRadius: '92% 0 0 0',
    filter: `drop-shadow(2px 2px 10px ${theme.palette.brand.black60})`,
  },

  '& div:last-of-type': {
    position: 'absolute',
    content: '""',
    left: -3,
    bottom: 0,
    width: 6,
    height: 6,
    borderRight: '1px solid',
    borderBottom: '1px solid',
    transform: 'rotate(45deg)',
    borderColor: theme.palette.primary.main,
    filter: `drop-shadow(2px 2px 10px ${theme.palette.brand.black60})`,
  },
}))

export const RowWrapArrow = () => (
  <RowWrapArrowWrapper>
    <div />
    <div />
    <div />
  </RowWrapArrowWrapper>
)

export const MobileWrapLine = styled('div')(({ theme }) => ({
  margin: '2px 20px',
  height: 40,
  width: 1,
  backgroundImage: `linear-gradient(${theme.palette.primary.main} 33%, rgba(255,255,255,0) 0%)`,
  backgroundPosition: 'left',
  backgroundSize: '1px 7px',
  backgroundRepeat: 'repeat-y',
  filter: `drop-shadow(1px 1px 1px ${theme.palette.brand.black60})`,
}))
