import React from 'react'
import { ICaseCardProps } from '../../case-card'
import { IStrapiCasesCardsTemplate, IStrapiSection, IStrapiTitle } from '../../../types/strapi'
import Title from '../../title/Title'
import DefaultCasesCards from './default-cases-cards/DefaultCasesCards'
import HomeGridCasesCards from './home-grid-cases-cards/HomeGridCasesCards'

export interface ICasesSectionData extends IStrapiSection {
  title?: IStrapiTitle
  cases: Array<ICaseCardProps>
  template?: IStrapiCasesCardsTemplate
}

export interface ICasesSectionProps {
  data: ICasesSectionData
}

const CasesSection: React.FC<ICasesSectionProps> = ({ data }) => {
  const { title, cases, template } = data

  const renderGrid = () => {
    switch (template) {
      case IStrapiCasesCardsTemplate.DEFAULT:
        return <DefaultCasesCards cases={cases} />

      case IStrapiCasesCardsTemplate.HOME_GRID:
        return <HomeGridCasesCards cases={cases} />

      default:
        // eslint-disable-next-line no-console
        console.log('Unknown cases grid template:', template)
        return <DefaultCasesCards cases={cases} />
    }
  }

  return (
    <>
      <Title title={title} hasSpacing />

      {renderGrid()}
    </>
  )
}

export default CasesSection
