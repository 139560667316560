import { CardWrapper } from '../SectionCard.styled'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { IStrapiActionCard } from '../../../types/strapi'
import ChevronDownIcon from '../../../icons/ChevronDown'
import Link from '../../link'
import transformStrapiLink from '../../../utils/transformStrapiLink'

export interface IFullAccordionCardProps extends IStrapiActionCard {}

const FullAccordionCard = (props: IFullAccordionCardProps) => {
  const { content, details, backgroundColor, link } = props

  return (
    <CardWrapper background={{ color: backgroundColor }}>
      <Accordion sx={{ alignSelf: 'stretch' }}>
        <AccordionSummary sx={{ 'p': 0, 'm': 0, '& .MuiAccordionSummary-content': { my: 0, mr: 4 } }} expandIcon={<ChevronDownIcon color='inherit' />}>
          <Wysiwyg data={content} />
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0, mt: 2 }}>
          <Wysiwyg data={details} />
        </AccordionDetails>
      </Accordion>

      {link && (
        <Typography
          variant='link'
          fontWeight={600}
          ml='auto'
          mt='auto'
          pt={4}
          {...(link && { component: Link, ...transformStrapiLink(link.link) })}
        >
          {link?.label}
        </Typography>
      )}
    </CardWrapper>
  )
}

export default FullAccordionCard
