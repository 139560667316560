import React from 'react'
import { IStrapiSectionOptions } from '../../types/strapi'
import ThemeContainer from '../../components/theme-container/ThemeContainer'
import { Box, BoxProps } from '@mui/material'
import transformStrapiSectionWidth from '../../utils/transformStrapiSectionWidth'
import transformStrapiPadding from '../../utils/transformStrapiPadding'

export interface ISectionLayoutProps extends BoxProps {
  options: IStrapiSectionOptions
  children: React.ReactNode
}

const BlockLayout: React.FC<ISectionLayoutProps> = ({ children, options, ...rest }) => {
  const { theme, width, paddingTop, paddingBottom } = options

  return (
    <ThemeContainer theme={theme}>
      <Box
        maxWidth={transformStrapiSectionWidth(width)}
        pt={transformStrapiPadding(paddingTop)}
        pb={transformStrapiPadding(paddingBottom)}
        {...rest}
      >
        {children}
      </Box>
    </ThemeContainer>
  )
}

export default BlockLayout
