import { CardWrapper } from '../SectionCard.styled'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { IStrapiActionCard } from '../../../types/strapi'
import { DetailsWrapper, SlideDownWrapper } from './SlideDownCard.styled'
import { Box } from '@mui/material'

export interface ISlideDownCardProps extends IStrapiActionCard {}

const SlideDownCard = (props: ISlideDownCardProps) => {
  const { content, details } = props

  return (
    <SlideDownWrapper>
      <DetailsWrapper>
        <Wysiwyg data={details} />
      </DetailsWrapper>

      <Box sx={{ height: '100%' }}>
        <CardWrapper>
          <Wysiwyg
            data={content}
            sx={{ width: '100%', my: 'auto' }}
          />
        </CardWrapper>
      </Box>
    </SlideDownWrapper>
  )
}

export default SlideDownCard
