import { IStrapiIconListItem, IStrapiIconSize, IStrapiTitleComponent } from '../../types/strapi'
import { Box, Stack, Typography } from '@mui/material'
import Image from '../image'
import Title from '../title/Title'

export interface IIconListItemProps extends IStrapiIconListItem {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
}

const IconListItem = (props: IIconListItemProps) => {
  const { icon, title, description, iconSize, direction = 'row' } = props

  const textAlign = direction === 'column' || direction === 'column-reverse' ? 'center' : 'left'

  return (
    <Stack spacing={4} direction={direction} alignItems='center'>
      <Image
        image={icon}
        sx={{
          width: castIconSize(iconSize),
          flexShrink: 0,
          alignSelf: description && title && (direction === 'row' || direction === 'row-reverse') ? 'flex-start' : 'center',
        }}
      />
      <div>
        <Title
          title={title}
          color='text.secondary'
          variant='body1'
          fontWeight={castIconListTitleWidth(title?.component)}
          hasSpacing={Boolean(description)}
          textAlign={textAlign}
        />

        {description && (
          <Typography variant='body1' fontSize={18} color='text.secondary' textAlign={textAlign}>
            {description}
          </Typography>
        )}
      </div>
    </Stack>
  )
}

export default IconListItem

export const castIconListTitleWidth = (titleComponent?: IStrapiTitleComponent): number => {
  switch (titleComponent) {
    case IStrapiTitleComponent.H1:
    case IStrapiTitleComponent.H2:
      return 700

    case IStrapiTitleComponent.H3:
    case IStrapiTitleComponent.H4:
      return 600

    default:
      return 400
  }
}

export const castIconSize = (iconSize?: IStrapiIconSize): { xs: number, md: number } => {
  switch (iconSize) {
    case IStrapiIconSize.EXTRA_LARGE:
      return { xs: 80, md: 120 }

    case IStrapiIconSize.LARGE:
      return { xs: 56, md: 80 }

    case IStrapiIconSize.MEDIUM:
      return { xs: 40, md: 56 }

    case IStrapiIconSize.SMALL:
      return { xs: 32, md: 32 }

    default:
      // eslint-disable-next-line no-console
      console.log('Unknown iconSize prop', iconSize)
      return { xs: 32, md: 32 }
  }
}

export interface IIconWrapperProps extends React.PropsWithChildren {
  isRoundedIcon: boolean
}

export const IconWrapper = (props: IIconWrapperProps) => {
  if (!props.isRoundedIcon) {
    return <>{props.children}</>
  }

  return (
    <Box sx={{ background: '#fff', borderRadius: '50%' }} p={4}>
      {props.children}
    </Box>
  )
}
