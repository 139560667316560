import { Script } from 'gatsby'
import usePageElements from './usePageElements'
import { IStrapiPageElementType } from '../../types/strapi'

const PageElements = () => {
  const { elements, isScriptsReady } = usePageElements()


  return (
    <>
      {elements.map((element, index) => {
        const attributes = element.attributes?.reduce((attributes, attribute) => ({ ...attributes, [attribute.key]: attribute.value || true }), {})

        switch (element.type) {
          case IStrapiPageElementType.LINK:
            return <link key={index} {...attributes} />

          case IStrapiPageElementType.META:
            return <meta key={index} {...attributes} />

          case IStrapiPageElementType.SCRIPT:
            return isScriptsReady
              ? <Script key={index} strategy='idle' {...attributes}>{element.body}</Script>
              : null

          default: {
            // eslint-disable-next-line no-console
            console.log('Unknown page element type', element.type)
            return null
          }
        }
      })}
    </>
  )
}

export default PageElements
