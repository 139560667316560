import Typography from '@mui/material/Typography'
import { IStrapiIconListItem, IStrapiIconSize, IStrapiLIconListItemsType, IStrapiSection, IStrapiTitle } from '../../../types/strapi'
import Title from '../../title/Title'
import DefaultIconList from './default-list/DefaultList'
import InterleavedList from './interleaved-list/InterleavedList'
import InterleavedRowList from './interleaved-row-list/InterleavedRowList'

export interface IIconListSectionData extends IStrapiSection {
  title?: IStrapiTitle
  description?: string
  listItems: Array<IStrapiIconListItem>
  itemsInRow: number
  iconSize?: IStrapiIconSize
  template?: IStrapiLIconListItemsType
}

export interface IIconListSectionProps {
  data: IIconListSectionData
}

const IconListSection = (props: IIconListSectionProps) => {
  const { title, description, template, ...listProps } = props.data

  const renderListItems = () => {
    switch (template) {
      case IStrapiLIconListItemsType.DEFAULT:
        return <DefaultIconList {...listProps} />

      case IStrapiLIconListItemsType.INTERLEAVED:
        return <InterleavedList {...listProps} />

      case IStrapiLIconListItemsType.INTERLEAVED_ROW:
        return <InterleavedRowList {...listProps} />

      default:
        // eslint-disable-next-line no-console
        console.log('Unknown template prop:', template)
        return <DefaultIconList {...listProps} />
    }
  }

  return (
    <>
      <Title title={title} hasSpacing />

      {description && (
        <Typography fontSize={20} mb={8} color='text.secondary'>
          {description}
        </Typography>
      )}

      {renderListItems()}
    </>
  )
}

export default IconListSection
