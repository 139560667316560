import { Grid, Stack, Typography } from '@mui/material'
import { IStrapiCase } from '../../types/strapi'
import { CaseCardFullWrapper, ImageWrapper, IndustryTag, TagWrapper } from './CaseCardFull.styled'
import Image from '../image'
import Link from '../link'
import CasesLocation from '../case-location/CaseLocation'

export interface ICaseCardFullProps extends IStrapiCase {}

const CaseCardFull = (props: ICaseCardFullProps) => {
  const { fullCardImage, location, url, title, description, industries } = props

  return (
    <Link url={url} newTab sx={{ height: '100%' }}>
      <CaseCardFullWrapper p={{ xs: 4, md: 5 }}>
        <Grid container spacing={{ xs: 4, lg: 5 }}>
          <Grid item xs={12} md={5} lg={4}>
            <ImageWrapper height={fullCardImage?.height || 250}>
              <Image image={fullCardImage} cover />
            </ImageWrapper>
          </Grid>

          <Grid item xs={12} md={7} lg={8} display='flex' flexDirection='column'>
            {title && (
              <Typography variant='h3' fontSize={32} fontWeight={700} mb={{ xs: 2, md: 3, lg: 4 }}>
                {title}
              </Typography>
            )}

            {description && (
              <Typography variant='body1'>
                {description}
              </Typography>
            )}

            <Stack spacing={{ xs: 4, md: 5 }} direction='row' alignItems='flex-end' flexGrow={1} mt={6}>
              {location && (
                <TagWrapper flexShrink={0}>
                  <CasesLocation {...location} />
                </TagWrapper>
              )}

              {industries && industries[0] && (
                <TagWrapper>
                  <IndustryTag variant='body2'>
                    {industries[0].name}
                  </IndustryTag>
                </TagWrapper>
              )}
            </Stack>
          </Grid>
        </Grid>
      </CaseCardFullWrapper>
    </Link>
  )
}

export default CaseCardFull
