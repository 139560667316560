import { Breakpoint, useTheme } from '@mui/material'
import { useLayoutEffect, useState } from 'react'

export const useSizeObserver = (ref: React.RefObject<Element>, size: number | Breakpoint = 'sm') => {
  const [ isLess, setIsLess ] = useState(true)
  const theme = useTheme()

  const breakpointValue = typeof size === 'number' ? size : theme.breakpoints.values[size]

  useLayoutEffect(() => {
    const element = ref?.current

    if (!element) {
      return
    }

    const observer = new ResizeObserver((entries) => {
      const elementWidth = entries[0].contentRect.width
      setIsLess(elementWidth < breakpointValue)
    })

    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [ ref, breakpointValue ])

  return isLess
}
