import React from 'react'
import { styled, lighten } from '@mui/material/styles'
import { IStrapiListIconColor } from '../../../types/strapi'
import { Box } from '@mui/material'

export const ListItemIconWrapper = styled('div')(({ theme }) => ({
  flexShrink: 0,
  minWidth: 20,
  minHeight: theme.typography.body1.lineHeight,
  fontSize: theme.typography.body1.fontSize,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const CheckIcon = styled('div')<{ color: IStrapiListIconColor }>(({ theme, color = 'primary' }) => {
  const fillColor: string = theme.palette[color].main

  return {
    boxSizing: 'content-box',
    margin: theme.spacing(2),
    width: 9,
    height: 4,
    borderBottom: `3px solid ${fillColor}`,
    borderLeft: `3px solid ${fillColor}`,
    transform: 'rotate(-45deg)',
  }
})

export const DashIcon = styled('span')<{color: IStrapiListIconColor}>(({ theme, color }) => ({
  display: 'inline-block',
  width: 12,
  height: 3,
  borderRadius: 1,
  backgroundColor: theme.palette[color].main,
}))

export const FilledCheckIcon = styled('div')<{ color?: IStrapiListIconColor }>(({ theme, color = 'primary' }) => {
  const fillColor = theme.palette[color].light
  const checkColor = theme.palette.background.default

  return {
    'backgroundColor': fillColor,
    'borderRadius': '50%',
    'width': theme.spacing(5),
    'height': theme.spacing(5),
    'marginTop': theme.spacing(2),
    'position': 'relative',
    '&::after': {
      boxSizing: 'content-box',
      content: '""',
      position: 'absolute',
      top: 5,
      left: 4,
      width: 9,
      height: 4,
      borderBottom: `3px solid ${checkColor}`,
      borderLeft: `3px solid ${checkColor}`,
      transform: 'rotate(-45deg)',
    },
  }
})

export const UpperlineIcon = styled('div')<{ color: IStrapiListIconColor }>(({ theme, color = 'primary' }) => ({
  width: '100%',
  borderTop: `4px solid ${theme.palette[color].light}`,
}))

export const StepIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Helvetica',
  letterSpacing: 1,
  fontWeight: 600,
  fontSize: 20,
  width: 50,
  height: 50,
  backgroundColor: theme.palette.text.primary,
  color: lighten(theme.palette.background.default, 0.2),
}))

export const DotIcon = styled('div')<{color: IStrapiListIconColor}>(({ theme, color }) => ({
  width: 5,
  height: 5,
  borderRadius: '50%',
  backgroundColor: theme.palette[color].main,
}))

export const DotsSquareIcon: React.FC<{ color: IStrapiListIconColor }> = ({ color = 'primary' }) => {
  return (
    <Box width={12} height={12} display='flex' flexWrap='wrap' gap='2px' m='auto'>
      {Array(4).fill(0).map((dot, index) => (
        <DotIcon key={index} color={color} />
      ))}
    </Box>
  )
}
