import { styled } from '@mui/material'

export const CardImageWrapper = styled('div')(() => ({
  'height': '100%',
  'boxSizing': 'border-box',
  'padding': '10px 4px',
}))

export const ImageCard = styled('div')(({ theme }) => ({
  'height': '100%',
  'boxSizing': 'border-box',
  'borderRadius': '8px',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'padding': 24,
  'backgroundColor': theme.palette.common.white,
  'transition': 'box-shadow ease-in-out .3s',

  '& > *': {
    transition: 'filter ease-in-out .3s',
    filter: 'grayscale(100%)',
  },

  '&:hover': {
    boxShadow: '0px 10px 10px 0px rgba(84, 89, 95, 0.1)',
  },

  '&:hover > *': {
    filter: 'grayscale(0%)',
    transition: 'filter ease-in-out .3s',
  },
}))
