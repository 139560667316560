import { graphql, useStaticQuery } from 'gatsby'
import { IStrapiPageElement, IStrapiPageElementAssignment } from '../../types/strapi'
import { useCallback, useMemo, useState } from 'react'
import useTimeoutOnComponentMount from '../../hooks/useTimeoutOnComponentMount'

interface IPageElementsQuery {
  allStrapiPageElement: {
    nodes: Array<IStrapiPageElement>
  }
}

const assignmentFilter = (element: IStrapiPageElement) => {
  const isDevelopment = process.env.GATSBY_IS_PREVIEW === 'true'

  if (element.assignment === IStrapiPageElementAssignment.OFF) {
    return false
  }

  if (element.assignment === IStrapiPageElementAssignment.COMMON) {
    return true
  }

  return isDevelopment
    ? element.assignment === IStrapiPageElementAssignment.DEV
    : element.assignment === IStrapiPageElementAssignment.PROD
}

const usePageElements = () => {
  const { allStrapiPageElement: { nodes: elements = [] } } = useStaticQuery<IPageElementsQuery>(graphql`
    query {
      allStrapiPageElement {
        nodes {
          ...StrapiPageElement
        }
      }
    }
  `)

  const [ isScriptsReady, setIsScriptsReady ] = useState(false)
  const callback = useCallback(() => setIsScriptsReady(true), [])
  useTimeoutOnComponentMount(callback)

  const filteredElements = useMemo(() => elements?.filter(assignmentFilter), [ elements ])

  return {
    elements: filteredElements || [],
    isScriptsReady,
  }
}

export default usePageElements
