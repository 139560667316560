import { Palette, PaletteColor, styled } from '@mui/material/styles'
import { IStrapiColor } from '../../../types/strapi'

interface IDividerProps {
  color?: IStrapiColor
}

export const Divider = styled('div', { shouldForwardProp: (props) => props !== 'color' })<IDividerProps>(({ theme, color }) => {
  const dividerColor = color
    ? color === IStrapiColor.TEXT
      ? theme.palette.text.secondary
      : (theme.palette[color as keyof Palette] as PaletteColor).main
    : theme.palette.text.secondary

  return {
    margin: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    width: 400,
    maxWidth: '90%',
    borderTop: '1px solid',
    borderColor: dividerColor,
  }
})
