import React from 'react'
import { CardWrapper } from '../SectionCard.styled'
import { IStrapiTextCard } from '../../../types/strapi'
import Wysiwyg from '../../wysiwyg/Wysiwyg'

export interface ITextCardProps extends IStrapiTextCard {}

const TextCard: React.FC<ITextCardProps> = (props) => {
  const { wysiwyg } = props

  return (
    <CardWrapper>
      <Wysiwyg data={wysiwyg} />
    </CardWrapper>
  )
}

export default TextCard
