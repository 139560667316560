import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const LoadingIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' {...props}>
      <path d='M3 12C3 12.4142 2.66419 12.75 2.25 12.75C1.83581 12.75 1.5 12.4142 1.5 12C1.5 6.21019 6.21019 1.5 12 1.5C14.8858 1.5 17.556 2.64994 19.5 4.62994V2.25C19.5 1.83581 19.8358 1.5 20.25 1.5C20.6642 1.5 21 1.83581 21 2.25V6.75C21 7.16419 20.6642 7.5 20.25 7.5H15.75C15.3358 7.5 15 7.16419 15 6.75C15 6.33581 15.3358 6 15.75 6H18.7327C17.0499 4.107 14.6291 3 12 3C7.03744 3 3 7.03744 3 12ZM21.75 11.25C21.3358 11.25 21 11.5858 21 12C21 16.9626 16.9626 21 12 21C9.37087 21 6.95006 19.893 5.26725 18H8.25C8.66419 18 9 17.6642 9 17.25C9 16.8358 8.66419 16.5 8.25 16.5H3.75C3.33581 16.5 3 16.8358 3 17.25V21.75C3 22.1642 3.33581 22.5 3.75 22.5C4.16419 22.5 4.5 22.1642 4.5 21.75V19.3701C6.444 21.3501 9.11419 22.5 12 22.5C17.7898 22.5 22.5 17.7898 22.5 12C22.5 11.5858 22.1642 11.25 21.75 11.25Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default LoadingIcon
