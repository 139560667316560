import { Grid } from '@mui/material'
import CaseCard, { ICaseCardProps } from '../../../case-card'
import getGridItemSize from '../../../../utils/getGridItemSize'

export interface IDefaultCasesCardsProps {
  cases: Array<ICaseCardProps>
}

const DefaultCasesCards = (props: IDefaultCasesCardsProps) => {
  const { cases } = props

  return (
    <Grid container spacing={4} py={2}>
      {cases.filter((c, i) => i < 3).map((card: ICaseCardProps) => (
        <Grid
          item
          key={card.title}
          {...getGridItemSize(Math.min(cases.length, 3))}
        >
          <CaseCard {...card} />
        </Grid>
      ))}
    </Grid>
  )
}

export default DefaultCasesCards
