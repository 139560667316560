import React from 'react'
import { IStrapiBasicCard } from '../../../../types/strapi'
import BasicCard from '../../../section-card/basic-card/BasicCard'
import CardsSection, { ICardsSectionData } from '..'

export interface IBasicCardsSectionData extends ICardsSectionData {
  cards: Array<IStrapiBasicCard>
}

export interface IBasicCardsSectionProps {
  data: IBasicCardsSectionData
}

const BasicCardsSection: React.FC<IBasicCardsSectionProps> = ({ data }) => {
  const { cards } = data

  return (
    <CardsSection CardComponent={BasicCard} data={data} cards={cards} />
  )
}

export default BasicCardsSection
