import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { IStrapiCardStyle, IStrapiSection, IStrapiTitle } from '../../../types/strapi'
import Title from '../../title/Title'
import CardStyleProvider from '../../../contexts/card-style-provider/CardStyleProvider'
import getGridItemSize from '../../../utils/getGridItemSize'
import { Box } from '@mui/material'

export interface ICardsSectionData extends IStrapiSection {
  title?: IStrapiTitle
  description?: string
  cardsInRow: number
  cardStyle: IStrapiCardStyle
}

export interface ICardsSectionProps<T> {
  data: ICardsSectionData
  CardComponent: React.FC<T>
  cards: Array<T>
}

const CardsSection = <T, >({ data, CardComponent, cards = [] }: ICardsSectionProps<T>) => {
  const { title, description, cardsInRow, cardStyle, ...rest } = data

  return (
    <CardStyleProvider cardStyle={cardStyle}>
      <Title title={title} hasSpacing />

      {description && (
        <Typography mb={4} color='text.secondary'>
          {description}
        </Typography>
      )}

      {(title || description) && <Box pt={{ xs: 2, lg: 4 }} />}

      <Grid container spacing={4}>
        {cards.map((card, index) => (
          <Grid item {...getGridItemSize(cardsInRow)} key={index}>
            <CardComponent {...card} as T {...rest} />
          </Grid>
        ))}
      </Grid>
    </CardStyleProvider>
  )
}

export default CardsSection
