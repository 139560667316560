import { styled } from '@mui/material/styles'
import { IStrapiImageSize } from '../../../types/strapi'

interface IImageWrapperProps {
  imageSize: IStrapiImageSize
}

const paddingHashXL = {
  [IStrapiImageSize.EXTRA_LARGE]: '0',
  [IStrapiImageSize.LARGE]: '10%',
  [IStrapiImageSize.NORMAL]: '20%',
  [IStrapiImageSize.SMALL]: '25%',
}

const paddingHashLG = {
  [IStrapiImageSize.EXTRA_LARGE]: '0',
  [IStrapiImageSize.LARGE]: '10%',
  [IStrapiImageSize.NORMAL]: '15%',
  [IStrapiImageSize.SMALL]: '20%',
}

const paddingHashMD = {
  [IStrapiImageSize.EXTRA_LARGE]: '0',
  [IStrapiImageSize.LARGE]: '15%',
  [IStrapiImageSize.NORMAL]: '25%',
  [IStrapiImageSize.SMALL]: '30%',
}

const paddingHashSM = {
  [IStrapiImageSize.EXTRA_LARGE]: '0',
  [IStrapiImageSize.LARGE]: '10%',
  [IStrapiImageSize.NORMAL]: '15%',
  [IStrapiImageSize.SMALL]: '20%',
}

export const ImageWrapper = styled('div')<IImageWrapperProps>(({ theme, imageSize }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: `0 ${paddingHashXL[imageSize]}`,

  [theme.breakpoints.down('lg')]: {
    padding: `0 ${paddingHashLG[imageSize]}`,
  },

  [theme.breakpoints.down('md')]: {
    padding: `0 ${paddingHashMD[imageSize]}`,
  },

  [theme.breakpoints.down('sm')]: {
    padding: `0 ${paddingHashSM[imageSize]}`,
  },
}))
