import React from 'react'
import { IStrapiReviewSlide } from '../../../../types/strapi'
import ReviewCard from '../../../section-card/review-card/ReviewCard'
import CardsSection, { ICardsSectionData } from '..'

export interface IReviewCardsSectionData extends ICardsSectionData {
  reviewCards: Array<IStrapiReviewSlide>
}

export interface IReviewCardsSectionProps {
  data: IReviewCardsSectionData
}

const ReviewCardsSection: React.FC<IReviewCardsSectionProps> = ({ data }) => {
  const { reviewCards } = data

  const cards = reviewCards.map(card => ({ ...card, authorDescription: card.site }))

  return (
    <CardsSection CardComponent={ReviewCard} data={data} cards={cards} />
  )
}

export default ReviewCardsSection
