import { styled } from '@mui/material'

export const CollapsedBlanked = styled('div')(({ theme }) => ({
  position: 'relative',
  height: 170,
  marginTop: -120,
  marginBottom: -50,
  background: `linear-gradient(0deg, ${theme.palette.background.default} 40%, rgba(0,0,0,0) 100%)`,

  left: '50%',
  transform: 'translateX(-50%)',
  width: 'var(--fixed100vw, 100vw)',

  [theme.breakpoints.down('md')]: {
    height: 120,
    marginTop: -70,
    marginBottom: -40,
  },
}))
