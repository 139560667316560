import { IStrapiButtonsAlignment } from '../types/strapi'

const castButtonsAlignment = (alignment?: IStrapiButtonsAlignment) => {
  switch (alignment) {
    case IStrapiButtonsAlignment.CENTER:
      return 'center'

    case IStrapiButtonsAlignment.LEFT:
      return 'flex-start'

    case IStrapiButtonsAlignment.RIGHT:
      return 'flex-end'

    default:
      return 'flex-start'
  }
}

export default castButtonsAlignment
