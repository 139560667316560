import React from 'react'
import { IStrapiCardStyle, IStrapiReview } from '../../../../types/strapi'
import CarouselSection, { ICarouselSectionData } from '..'
import ReviewCard from '../../../section-card/review-card/ReviewCard'
import CardStyleProvider from '../../../../contexts/card-style-provider/CardStyleProvider'

export interface IReviewsCarouselSectionData extends ICarouselSectionData {
  reviews: Array<IStrapiReview>
  cardStyle: IStrapiCardStyle
}

export interface IReviewsCarouselSectionProps {
  data: IReviewsCarouselSectionData
}

const ReviewsCarouselSection: React.FC<IReviewsCarouselSectionProps> = ({ data }) => {
  const { reviews, cardStyle } = data

  return (
    <CardStyleProvider cardStyle={cardStyle}>
      <CarouselSection data={data} SlideComponent={ReviewCard} slides={reviews} />
    </CardStyleProvider>
  )
}

export default ReviewsCarouselSection
