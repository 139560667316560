import React, { useRef } from 'react'
import { CardWrapper } from '../SectionCard.styled'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { IStrapiDesktopAlignment, IStrapiMobileAlignment, IStrapiSimpleCard } from '../../../types/strapi'
import { Stack } from '@mui/material'
import Image from '../../image'
import { useSizeObserver } from '../../../hooks/useSizeObserver'

export interface ISimpleCardProps extends IStrapiSimpleCard {
  desktopAlignment: IStrapiDesktopAlignment
  mobileAlignment: IStrapiMobileAlignment
}

const SimpleCard: React.FC<ISimpleCardProps> = (props) => {
  const { content, image, desktopAlignment, mobileAlignment } = props

  const cardRef = useRef(null)
  const isMobileLayout = useSizeObserver(cardRef, 500)

  const alignment = isMobileLayout
    ? castMobileAlignment(mobileAlignment)
    : castDesktopAlignment(desktopAlignment)

  return (
    <CardWrapper ref={cardRef}>
      <Stack spacing={6} direction={isMobileLayout ? 'column' : 'row'} alignItems={alignment} alignSelf='stretch'>
        <Wysiwyg data={content} sx={{ maxWidth: isMobileLayout ? '100%' : '55%' }} />
        <Image image={image} sx={{ width: isMobileLayout ? '60%' : '45%' }} />
      </Stack>
    </CardWrapper>
  )
}

export default SimpleCard

export const castDesktopAlignment = (alignment: IStrapiDesktopAlignment) => {
  switch (alignment) {
    case IStrapiDesktopAlignment.TOP:
      return 'flex-start'

    case IStrapiDesktopAlignment.CENTER:
      return 'center'

    case IStrapiDesktopAlignment.BOTTOM:
      return 'flex-end'

    default:
      return 'center'
  }
}

export const castMobileAlignment = (alignment: IStrapiMobileAlignment) => {
  switch (alignment) {
    case IStrapiMobileAlignment.LEFT:
      return 'flex-start'

    case IStrapiMobileAlignment.CENTER:
      return 'center'

    case IStrapiMobileAlignment.RIGHT:
      return 'flex-end'

    default:
      return 'center'
  }
}

export const castImageYPosition = (alignment: IStrapiDesktopAlignment) => {
  switch (alignment) {
    case IStrapiDesktopAlignment.TOP:
      return 'top'

    case IStrapiDesktopAlignment.CENTER:
      return 'center'

    case IStrapiDesktopAlignment.BOTTOM:
      return 'bottom'

    default:
      return 'bottom'
  }
}
