import { IStrapiBlogPost, IStrapiImage } from '../../../types/strapi'
import Typography from '@mui/material/Typography'
import Link from '../../link'
import { ImageWrapper } from './BlogSlide.styled'
import ImageLink from '../../image-link'

export interface ICaseSlideProps extends IStrapiBlogPost {}

const BlogSlide = (props: ICaseSlideProps) => {
  const { title, slug, image, featuredImage } = props

  const blogUrl = slug ? '/blog/' + slug : null

  const renderTitle = <Typography variant='body2' component='h3' fontWeight={400}>{title}</Typography>

  const postImage = image || { localFile: featuredImage, alternativeText: title } as IStrapiImage

  return (
    <>
      <ImageWrapper mb={4}>
        <ImageLink cover image={postImage} {...(blogUrl && { link: { url: blogUrl } })} />
      </ImageWrapper>

      {blogUrl ? (
        <Link to={blogUrl}>{renderTitle}</Link>
      ) : (
        <>{renderTitle}</>
      )}
    </>
  )
}

export default BlogSlide
