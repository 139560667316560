import React from 'react'
import Grid, { GridProps } from '@mui/material/Grid'
import { IStrapiGridElement, IStrapiGridSpacing, IStrapiSection, IStrapiTitle, IStrapiFlexDirection, IStrapiFlexAlignment, IStrapiGridMobileBehavior, IStrapiGridItemSize } from '../../../types/strapi'
import renderSection from '../../../utils/renderSection'
import transformStrapiGridSize from '../../../utils/transformStrapiGridSize'
import castStrapiColumnSpacing, { castStrapiRowSpacing } from '../../../utils/castStrapiGridSpacing'
import { useIsMobile } from '../../../hooks/useIsMobile'
import BlockLayout from '../../../layouts/block-layout/BlockLayout'
import Title from '../../title/Title'
import { useSectionsContext } from '../../../hooks/useSectionsContext'

export interface IGridSectionData extends IStrapiSection {
  title?: IStrapiTitle
  spacing: IStrapiGridSpacing
  rowSpacing?: IStrapiGridSpacing
  blocks: Array<IStrapiGridElement>
  alignment: IStrapiFlexAlignment
  direction: IStrapiFlexDirection
  mobileAlignment: IStrapiFlexAlignment
  mobileDirection: IStrapiFlexDirection
}

export interface IGridSectionProps {
  data: IGridSectionData
}

const GridSection: React.FC<IGridSectionProps> = ({ data }) => {
  const { blocks = [], spacing, rowSpacing, alignment, direction, title, mobileAlignment, mobileDirection } = data

  const isMobile = useIsMobile('md')

  const { sections } = useSectionsContext()
  const gridIdsSet = new Set(blocks.map(block => block.sectionId).filter(sectionId => !!sectionId))
  const gridSections = sections?.filter(section => section.options.anchor && gridIdsSet.has(section.options.anchor))

  const containerFlexProps = isMobile && mobileDirection === IStrapiFlexDirection.ROW
    ? { direction: castFlexDirection(mobileDirection), alignItems: castFlexAlignment(mobileAlignment) }
    : { direction: castFlexDirection(direction), alignItems: castFlexAlignment(alignment) }

  return (
    <>
      <Title title={title} hasSpacing />

      <Grid
        container
        columnSpacing={castStrapiColumnSpacing(spacing)}
        rowSpacing={castStrapiRowSpacing(rowSpacing)}
        {...containerFlexProps}
      >
        {blocks.map(block => {
          const blockSections = gridSections.filter(section => section.options.anchor === block.sectionId)

          const blockOrder = isMobile ? castMobileGridOrder(block.mobileBehavior) : 1

          if (blockOrder === null) {
            return null
          }

          const gridItemSize = isMobile && mobileDirection === IStrapiFlexDirection.ROW ? { xs: castMobileGridSize(block.width) } : transformStrapiGridSize(block.width)

          return (
            <Grid item key={block.sectionId} order={blockOrder} {...gridItemSize}>
              {blockSections.map((section, index) => (
                <BlockLayout key={`${section.options.anchor}${index}`} options={section.options}>
                  {renderSection(section)}
                </BlockLayout>
              ))}
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default GridSection

export const castFlexDirection = (direction?: IStrapiFlexDirection): GridProps['direction'] => {
  switch (direction) {
    case IStrapiFlexDirection.ROW:
      return 'row'

    case IStrapiFlexDirection.COLUMN:
      return 'column'

    default:
      return 'row'
  }
}

export const castFlexAlignment = (alignment?: IStrapiFlexAlignment): GridProps['alignItems'] => {
  switch (alignment) {
    case IStrapiFlexAlignment.START:
      return 'flex-start'

    case IStrapiFlexAlignment.CENTER:
      return 'center'

    case IStrapiFlexAlignment.END:
      return 'flex-end'

    default:
      return 'flex-start'
  }
}

export const castMobileGridOrder = (mobileBehavior?: IStrapiGridMobileBehavior): number | null => {
  switch (mobileBehavior) {
    case IStrapiGridMobileBehavior.FIRST:
      return 0

    case IStrapiGridMobileBehavior.DEFAULT:
      return 1

    case IStrapiGridMobileBehavior.LAST:
      return 2

    case IStrapiGridMobileBehavior.HIDDEN:
      return null

    default:
      return 1
  }
}

export const castMobileGridSize = (size: IStrapiGridItemSize) => {
  switch (size) {
    case IStrapiGridItemSize.SIZE_25:
      return 3

    case IStrapiGridItemSize.SIZE_33:
      return 4

    case IStrapiGridItemSize.SIZE_40:
      return 5

    case IStrapiGridItemSize.SIZE_50:
      return 6

    case IStrapiGridItemSize.SIZE_60:
      return 7

    case IStrapiGridItemSize.SIZE_66:
      return 8

    case IStrapiGridItemSize.SIZE_75:
      return 9

    case IStrapiGridItemSize.SIZE_100:
      return 12

    case IStrapiGridItemSize.AUTO:
      return false

    default:
      return 12
  }
}