import { CardWrapper, castCardPadding } from '../SectionCard.styled'
import { IStrapiPriceCard } from '../../../types/strapi'
import Title from '../../title/Title'
import { Accordion, AccordionDetails, Box, Typography } from '@mui/material'
import ChevronDownIcon from '../../../icons/ChevronDown'
import { CollapsedBlanked, FeatureCheckIcon, FeatureItemWrapper, PriceWrapper } from './PriceCard.styled'
import { useCardStyleContext } from '../../../hooks/useCardStyleContext'

export interface IPriceCardProps extends IStrapiPriceCard {
  collapsed?: boolean
  onClick?: () => void
  showCollapsedArrow?: boolean
}

const PriceCard = (props: IPriceCardProps) => {
  const { title, price, features, collapsed = false, onClick, showCollapsedArrow = false } = props

  const { cardPadding } = useCardStyleContext()
  const { px } = castCardPadding(cardPadding)

  const featuresList: Array<string> = features.split(`\n`).map(t => t.trim()).filter(t => !!t)

  return (
    <div>
      <CardWrapper>
        <Title title={title} hasSpacing onClick={onClick} />

        <PriceWrapper sx={{ paddingLeft: px, marginLeft: { xs: -px.xs, md: -px.md } }}>
          <Typography variant='body1' fontWeight={500}>{price}</Typography>
        </PriceWrapper>

        <Box mt='34px'>
          {featuresList.slice(0, 2).map((feature, index) => (
            <FeatureItem key={index} hasSeparator={index > 0}>{feature}</FeatureItem>
          ))}
        </Box>

        <Accordion expanded={!collapsed}>
          <div />
          <AccordionDetails>
            {featuresList.slice(2).map((feature, index) => (
              <FeatureItem key={index} hasSeparator>{feature}</FeatureItem>
            ))}
          </AccordionDetails>
        </Accordion>
      </CardWrapper>

      {collapsed && (
        <CollapsedBlanked onClick={onClick}>
          {showCollapsedArrow && (
            <>
              <Typography variant='body2' fontWeight={400} color='primary'>See all features</Typography>
              <ChevronDownIcon sx={{ fontSize: '26px', marginLeft: '8px' }} />
            </>
          )}
        </CollapsedBlanked>
      )}
    </div>
  )
}

export default PriceCard

interface IFeatureItemProps extends React.PropsWithChildren {
  hasSeparator: boolean
}

const FeatureItem = (props: IFeatureItemProps) => {
  return (
    <FeatureItemWrapper hasSeparator={props.hasSeparator}>
      <FeatureCheckIcon />
      <Typography variant='body2'>{props.children}</Typography>
    </FeatureItemWrapper>
  )
}
