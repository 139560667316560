import { Grid, Stack, Typography } from '@mui/material'
import { IStrapiKeyValueItem, IStrapiSection, IStrapiTitle } from '../../../types/strapi'
import Title from '../../title/Title'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { TableRow } from './TableSection.styled'

export interface ITableSectionData extends IStrapiSection {
  title?: IStrapiTitle
  rows: Array<IStrapiKeyValueItem>
}

export interface ITabsListSectionProps {
  data: ITableSectionData
}

const TableSection = (props: ITabsListSectionProps) => {
  const { title, rows } = props.data

  return (
    <>
      <Title title={title} hasSpacing={Boolean(rows?.length)} />

      {rows.map((row, index) => (
        <TableRow key={index}>
          <Grid container spacing={{ xs: 4, md: 6, lg: 10 }}>
            <Grid item xs={12} md={4} lg={3}>
              <Stack direction='row' spacing={{ xs: 4, md: 6, lg: 10 }}>
                <Typography variant='body2' fontSize={20} fontWeight={400}>
                  {('00' + (index + 1)).slice(-2)}
                </Typography>

                <Typography variant='body2' component='h3' fontSize={20} fontWeight={400}>
                  {row.key}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} md>
              <Wysiwyg data={row.value} />
            </Grid>
          </Grid>
        </TableRow>
      ))}
    </>
  )
}

export default TableSection
