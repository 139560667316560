import { Box, BoxProps } from '@mui/material'

export interface IFullWidthContainerProps extends BoxProps {
  disable: boolean
}

const FullWidthContainer = (props: IFullWidthContainerProps) => {
  const { disable = false, children, sx, ...rest } = props

  if (disable) {
    return <>{children}</>
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: 'var(--fixed100vw, 100vw)',
        left: '50%',
        marginLeft: '-50vw',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default FullWidthContainer
