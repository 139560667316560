import { Box, Stack } from '@mui/material'
import { IStrapiColor, IStrapiTabIndicator, IStrapiTabsPosition } from '../../../../types/strapi'
import renderSection, { ISectionData } from '../../../../utils/renderSection'
import BlockLayout from '../../../../layouts/block-layout/BlockLayout'
import { StyledTab, StyledTabs, TabButton } from '../TabsSection.styled'

export interface IDesktopTabsProps {
  tabsGrouped: Record<string, Array<string>>
  sections: Array<ISectionData>
  activeTab: string
  indicatorColor: IStrapiColor
  tabsPosition: IStrapiTabsPosition
  indicator: IStrapiTabIndicator
  onChange: (_: React.SyntheticEvent, tabLabel: string) => void
}

const DesktopTabs = (props: IDesktopTabsProps) => {
  const { tabsGrouped, sections, activeTab, indicatorColor, tabsPosition, indicator, onChange } = props

  const isLeftTabsPosition = tabsPosition === IStrapiTabsPosition.LEFT

  return (
    <Box display='flex' flexDirection={isLeftTabsPosition ? 'row' : 'column'}>
      <Box sx={{ flexShrink: 0, mb: 5, mr: isLeftTabsPosition ? { md: 5, lg: 10, xl: 20 } : 0 }}>
        {indicator !== IStrapiTabIndicator.BUTTON && (
          <StyledTabs
            variant='scrollable'
            scrollButtons='auto'
            value={activeTab}
            orientation={isLeftTabsPosition ? 'vertical' : 'horizontal'}
            onChange={onChange}
            activeColor={indicatorColor}
            centered={tabsPosition === IStrapiTabsPosition.CENTER}
            TabIndicatorProps={{
              sx: {
                ...(indicator === IStrapiTabIndicator.NONE && { display: 'none' }),
                ...(isLeftTabsPosition ? { width: 2 } : { height: 2 }),
              },
            }}
          >
            {Object.keys(tabsGrouped).map((label) => (
              <StyledTab
                disableRipple
                key={label}
                label={label}
                value={label}
                activeColor={indicatorColor}
              />
            ))}
          </StyledTabs>
        )}

        {indicator === IStrapiTabIndicator.BUTTON && (
          <Stack
            direction={isLeftTabsPosition ? 'column' : 'row'}
            justifyContent={tabsPosition === IStrapiTabsPosition.CENTER ? 'center' : 'flex-start'}
            flexWrap='wrap'
            mr={isLeftTabsPosition ? { xs: -2, md: -3, lg: -4 } : { xs: -4, md: -5, lg: -6, xl: -8 }}
            mb={isLeftTabsPosition ? { xs: -2, md: -3, lg: -4 } : { xs: -4, md: -5, lg: -6, xl: -8 }}
          >
            {Object.keys(tabsGrouped).map((label) => (
              <TabButton
                key={label}
                variant={activeTab === label ? 'contained' : 'outlined'}
                color={castIndicatorColor(indicatorColor)}
                activeColor={indicatorColor}
                onClick={(event) => onChange(event, label)}
                sx={{
                  mb: isLeftTabsPosition ? { xs: 2, md: 3, lg: 4 } : { xs: 4, md: 5, lg: 6, xl: 8 },
                  mr: isLeftTabsPosition ? { xs: 2, md: 3, lg: 4 } : { xs: 4, md: 5, lg: 6, xl: 8 },
                }}
              >
                {label}
              </TabButton>
            ))}
          </Stack>
        )}
      </Box>

      <Box flexGrow='1'>
        {sections.map((tabSection) => (
          <BlockLayout
            key={tabSection.options.anchor}
            options={tabSection.options}
            role='tabpanel'
            hidden={!tabSection.options.anchor || !tabsGrouped[activeTab].includes(tabSection.options.anchor)}
            id={tabSection.options.anchor}
            aria-labelledby={tabSection.options.anchor}
          >
            {renderSection(tabSection)}
          </BlockLayout>
        ))}
      </Box>
    </Box>
  )
}

export default DesktopTabs

const castIndicatorColor = (color: IStrapiColor) => {
  switch (color) {
    case IStrapiColor.PRIMARY:
      return 'primary'

    case IStrapiColor.SECONDARY:
      return 'secondary'

    case IStrapiColor.TERTIARY:
      return 'tertiary'

    default:
      return undefined
  }
}
