import Box from '@mui/material/Box'
import { Suspense, useCallback, useRef, useState } from 'react'
import useIsInView from './useIsInView'
import { isClient } from '../utils/isClient'

const useLazyWrapper = (lazyComponent: any) => {
  const [ isReady, setReady ] = useState<boolean>(false)
  const skeletonRef = useRef(null)

  const callback = useCallback(() => setReady(true), [])

  useIsInView(skeletonRef, callback)

  if (!isClient()) {
    return null
  }

  if (!isReady) {
    return (<Box ref={skeletonRef} height={400} />)
  }

  skeletonRef.current = null

  return (
    <Suspense fallback={<Box height={400} />}>
      {lazyComponent}
    </Suspense>
  )
}

export default useLazyWrapper
