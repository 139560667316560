import React from 'react'
import { IStrapiCardStyle, IStrapiTextCard } from '../../../../types/strapi'
import CarouselSection, { ICarouselSectionData } from '..'
import CardStyleProvider from '../../../../contexts/card-style-provider/CardStyleProvider'
import TextCard from '../../../section-card/text-card/TextCard'

export interface ITextCardsCarouselSectionData extends ICarouselSectionData {
  textCards: Array<IStrapiTextCard>
  cardStyle: IStrapiCardStyle
}

export interface ITextCardsCarouselSectionProps {
  data: ITextCardsCarouselSectionData
}

const TextCardsCarouselSection: React.FC<ITextCardsCarouselSectionProps> = ({ data }) => {
  const { textCards, cardStyle } = data

  return (
    <CardStyleProvider cardStyle={cardStyle}>
      <CarouselSection data={data} SlideComponent={TextCard} slides={textCards} />
    </CardStyleProvider>
  )
}

export default TextCardsCarouselSection
