import { IStrapiCase } from '../../../types/strapi'
import Typography from '@mui/material/Typography'
import Image from '../../image'
import { Box } from '@mui/material'
import { SlideContainer } from './CaseSlide.styled'
import { useIsMobile } from '../../../hooks/useIsMobile'
import Link from '../../link'

export interface ICaseSlideProps extends IStrapiCase {}

const CaseSlide = (props: ICaseSlideProps) => {
  const { title, description, url, image } = props

  const isMobile = useIsMobile('lg')

  return (
    <SlideContainer sx={{ ...(!isMobile && { aspectRatio: '7 / 5' }) }}>
      <Image
        image={image}
        cover
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '10px',
          zIndex: -1,
        }}
      />

      <Box p={{ xs: 6, lg: 8, xl: 10 }}>
        {url ? (
          <Link to={url}>
            <Typography color='common.white' variant='h2' component='h3'>
              {title}
            </Typography>
          </Link>
        ) : (
          <Typography color='common.white' variant='h2' component='h3'>
            {title}
          </Typography>
        )}

        {description && (
          <Typography color='common.white' variant='body2' mt={{ xs: 4, md: 6 }}>
            {description}
          </Typography>
        )}
      </Box>
    </SlideContainer>
  )
}

export default CaseSlide
