import { ICardsSectionData } from '..'
import { IStrapiPriceCard } from '../../../../types/strapi'
import CardStyleProvider from '../../../../contexts/card-style-provider/CardStyleProvider'
import Title from '../../../title/Title'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import MobilePriceCards from './mobile-price-cards/MobilePriceCards'
import DesktopPriceCards from './desktop-price-cards/DesktopPriceCards'

export interface IPriceCardsSectionData extends ICardsSectionData {
  priceCards: Array<IStrapiPriceCard>
}

export interface IPriceCardsSectionProps {
  data: IPriceCardsSectionData
}

const PriceCardsSection = (props: IPriceCardsSectionProps) => {
  const { priceCards, cardStyle, title } = props.data

  const isMobile = useIsMobile('md')

  return (
    <CardStyleProvider cardStyle={cardStyle}>
      <Title title={title} hasSpacing={Boolean(priceCards.length)} />

      {isMobile ? (
        <MobilePriceCards priceCards={priceCards} />
      ) : (
        <DesktopPriceCards priceCards={priceCards} />
      )}
    </CardStyleProvider>
  )
}

export default PriceCardsSection
