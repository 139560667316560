import { useEffect } from 'react'

const DEFAULT_TIME_DELAY = 5000

const useTimeoutOnComponentMount = (callback: () => void, delay: number = DEFAULT_TIME_DELAY) => {
  useEffect(() => {
    const timer = setTimeout(callback, delay)
    return () => clearTimeout(timer)
  }, [ callback, delay ])
}

export default useTimeoutOnComponentMount
