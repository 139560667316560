import React from 'react'
import { IStrapiPriceTag, IStrapiSection, IStrapiTitle } from '../../../types/strapi'
import Title from '../../title/Title'
import { Grid } from '@mui/material'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { StaticImage } from 'gatsby-plugin-image'
import { TagBody, TagPrice, TagWrapper, TagsBackground, TagsRowWrapper } from './PriceTagSection.styled'
import { useIsMobile } from '../../../hooks/useIsMobile'

export interface IPriceTagsSectionData extends IStrapiSection {
  title?: IStrapiTitle
  priceTags?: Array<IStrapiPriceTag>
}

export interface IPriceTagsSectionProps {
  data: IPriceTagsSectionData
}

const PriceTagsSection: React.FC<IPriceTagsSectionProps> = ({ data }) => {
  const { title, priceTags } = data

  const priceTagsSafe = priceTags || []
  const isMobile = useIsMobile('md')
  const isSmallDesktop = useIsMobile('lg')
  const tagsInRow = isMobile ? 2 : isSmallDesktop ? 3 : 5

  const rowsTags = (priceTagsSafe).reduce((rows: Array<Array<IStrapiPriceTag>>, _, index) => {
    if (index % tagsInRow === 0) {
      rows.push(priceTagsSafe.slice(index, index + tagsInRow))
    }
    return rows
  }, [])

  return (
    <>
      <Title title={title} hasSpacing={Boolean(priceTagsSafe.length)} />

      {rowsTags.map((row, rowIndex) => (
        <TagsRowWrapper key={rowIndex}>
          <TagsBackground />

          <Grid container wrap='nowrap'>
            {row.map((tag, index) => (
              <Grid item xs key={index} mx={{ xs: -2, sm: 2 }}>
                <TagWrapper>
                  <StaticImage src='../../../../static/images/price-tag-1.avif' alt='price-head' />
                  <TagBody>
                    <Wysiwyg data={tag.content} sx={{ px: 3 }} />
                    <TagPrice fontWeight={400} sx={{ pl: 3, pr: 6, mt: 5 }}>{tag.price}</TagPrice>
                  </TagBody>
                </TagWrapper>
              </Grid>
            ))}
          </Grid>
        </TagsRowWrapper>
      ))}

    </>
  )
}

export default PriceTagsSection
