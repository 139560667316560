export enum SectionComponentType {
  DEFAULT_SECTION = 'sections.default-section',
  CASES_SECTION = 'sections.cases-section',
  BASIC_CARDS_SECTION = 'sections.cards-section',
  TEXT_CARDS_SECTION = 'sections.text-cards-section',
  LICENSE_CARDS_SECTION = 'sections.license-cards-section',
  IMAGE_CARDS_SECTION = 'sections.image-cards-section',
  REVIEW_CARDS_SECTION = 'sections.review-cards-section',
  LIST_SECTION = 'sections.list-section',
  QUOTE_SECTION = 'sections.quote-section',
  IMAGES_CAROUSEL_SECTION = 'sections.carousel-section',
  REVIEWS_CAROUSEL_SECTION = 'sections.reviews-carousel-section',
  LICENSES_CAROUSEL_SECTION = 'sections.licenses-carousel-section',
  CASES_CAROUSEL_SECTION = 'sections.cases-carousel-section',
  TEXT_CARDS_CAROUSEL_SECTION = 'sections.text-cards-carousel-section',
  TABS_LAYOUT = 'sections.tabs-layout',
  GRID_LAYOUT = 'sections.grid-layout',
  IMAGE_SECTION = 'sections.image-section',
  BUTTONS_SECTION = 'sections.buttons-section',
  ICON_LIST_SECTION = 'sections.icon-list-section',
  CASES_SELECTION_SECTION = 'sections.cases-selection-section',
  WYSIWYG_SECTION = 'sections.wysiwyg-section',
  BLOG_SECTION = 'sections.blog-section',
  STEPS_SECTION = 'sections.steps-section',
  SOCIALS_SECTION = 'sections.socials-section',
  FAQ_SECTION = 'sections.faq-section',
  BREAD_CRUMBS_SECTION = 'sections.bread-crumbs-section',
  TABS_LIST_SECTION = 'sections.tabs-list-section',
  ACTION_CARDS_SECTION = 'sections.action-cards-section',
  TABLE_SECTION = 'sections.table-section',
  PRICE_TAGS_SECTION = 'sections.price-tags-section',
  PRICE_CARDS_SECTION = 'sections.price-cards-section',
  SIMPLE_CARDS_SECTION = 'sections.simple-cards-section',
  HOVERING_SECTION = 'sections.hovering-section',
  PRICE_LABEL_CARDS_SECTION = 'sections.price-label-cards-section',
}
