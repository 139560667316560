import { styled } from '@mui/material/styles'

export const TableRow = styled('div')(({ theme }) => ({
  'backgroundColor': theme.palette.brand.lightGray,
  'borderRadius': 10,
  'padding': theme.spacing(8),
  'transition': 'background-color ease-in-out .2s, color ease-in-out .2s',

  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(4),
  },

  '&:hover': {
    backgroundColor: theme.palette.brand.charcoalGray,
  },

  '&:hover .MuiTypography-root': {
    color: theme.palette.common.white,
  },
}))
