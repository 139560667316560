import { CardWrapper } from '../SectionCard.styled'
import { IStrapiBasicCard, IStrapiCardAlignment } from '../../../types/strapi'
import Image from '../../image'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import Title from '../../title/Title'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { Stack } from '@mui/material'
import { useCardStyleContext } from '../../../hooks/useCardStyleContext'

export interface IBasicCardProps extends IStrapiBasicCard {}

const BasicCard = (props: IBasicCardProps) => {
  const { title, content, image, background, link } = props

  const isMobile = useIsMobile('md')

  const { alignment } = useCardStyleContext()

  return (
    <CardWrapper background={background} link={link}>
      <Stack direction={isMobile ? 'row' : 'column'} spacing={isMobile ? 5 : 4} alignItems={isMobile ? 'center' : 'flex-start'}>
        <Image image={image} sx={{ width: isMobile ? 40 : 56, height: 'auto' }} />

        <Title title={title} sx={{ ...(!isMobile && alignment === IStrapiCardAlignment.CENTER && { textAlign: 'center' }) }} />
      </Stack>

      <Wysiwyg data={content} sx={{ mt: image || title ? 4 : 0, ...(alignment === IStrapiCardAlignment.CENTER && { textAlign: 'center' }) }} />
    </CardWrapper>
  )
}

export default BasicCard
