import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const QuoteDefaultIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 42 34' {...props}>
      <path d='M18.0218 0.767996L11.6858 23.808L9.57375 15.168C12.3258 15.168 14.5658 15.968 16.2938 17.568C18.0218 19.168 18.8858 21.376 18.8858 24.192C18.8858 26.944 17.9898 29.184 16.1978 30.912C14.4698 32.576 12.2938 33.408 9.66975 33.408C6.98175 33.408 4.74175 32.576 2.94975 30.912C1.22175 29.184 0.35775 26.944 0.35775 24.192C0.35775 23.36 0.42175 22.56 0.54975 21.792C0.67775 20.96 0.93375 20 1.31775 18.912C1.70175 17.824 2.24575 16.384 2.94975 14.592L8.51775 0.767996H18.0218ZM40.6778 0.767996L34.3418 23.808L32.2298 15.168C34.9818 15.168 37.2218 15.968 38.9498 17.568C40.6778 19.168 41.5418 21.376 41.5418 24.192C41.5418 26.944 40.6458 29.184 38.8538 30.912C37.1258 32.576 34.9498 33.408 32.3258 33.408C29.6378 33.408 27.3978 32.576 25.6058 30.912C23.8778 29.184 23.0138 26.944 23.0138 24.192C23.0138 23.36 23.0778 22.56 23.2058 21.792C23.3338 20.96 23.5898 20 23.9738 18.912C24.3578 17.824 24.9018 16.384 25.6058 14.592L31.1738 0.767996H40.6778Z' fill='#343B4C' />
    </SvgIcon>
  )
}

export default QuoteDefaultIcon
