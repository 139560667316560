import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material'
import { IStrapiFAQTemplate, IStrapiFaqItem, IStrapiSection, IStrapiTitle } from '../../../types/strapi'
import Title from '../../title/Title'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { ExpandIcon } from './FaqSection.styled'

export interface IFaqSectionData extends IStrapiSection {
  title: IStrapiTitle
  faqItems: Array<IStrapiFaqItem>
  template: IStrapiFAQTemplate
}

export interface IFaqSectionProps {
  data: IFaqSectionData
}

const FaqSection = (props: IFaqSectionProps) => {
  const { title, faqItems, template } = props.data

  const [ expanded, setExpanded ] = useState<number | null>(null)

  const handleChange =
    (panelIndex: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panelIndex : null)
    }

  return (
    <>
      <Title title={title} hasSpacing />

      {faqItems.map((faq, index) => (
        <React.Fragment key={index}>
          {Boolean(index) && (<Divider />)}

          <Accordion expanded={expanded === index} onChange={handleChange(index)}>
            <AccordionSummary id={`faq-${index + 1}`} aria-controls={`faq-${index + 1}`} sx={{ py: { xs: 2, md: 4 } }}>
              <ExpandIcon isExpanded={expanded === index} template={template} sx={{ mr: 6, flexShrink: 0 }} />
              <Typography variant='h3' component='p'>
                {faq.question}
              </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ mb: 5 }}>
              <Wysiwyg data={faq.answer} />
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      ))}
    </>
  )
}

export default FaqSection
