import Typography from '@mui/material/Typography'
import Image from '../image'
import { IStrapiCase } from '../../types/strapi'
import Link from '../link'
import { ArrowIcon, CardDescription, CardTechnologies, CaseCardWrapper } from './CaseCard.styled'
import { Box, Grid } from '@mui/material'
import useIsLazySection from '../../hooks/useIsLazySection'

export interface ICaseCardProps extends IStrapiCase {}

const CaseCard = (props: ICaseCardProps) => {
  const { title, description, services, technologies, url, image } = props

  const technologiesList = technologies?.split(`\n`).map(t => t.trim()).filter(t => !!t).join('   ')

  const isLazy = useIsLazySection()

  return (
    <Link url={url} newTab sx={{ height: '100%' }}>
      <CaseCardWrapper>
        <Image
          image={image}
          cover
          sx={{
            position: 'absolute',
            zIndex: -1,
            opacity: 1,
          }}
          isLazy={isLazy}
        />

        {title && (
          <Typography variant='body2' component='h3' fontSize={24} fontWeight={700} lineHeight='30px' mx={{ xs: 4, md: 6 }} mt={{ xs: 4, md: 6 }}>
            {title}
          </Typography>
        )}

        <Box className='hover-container' mx={{ xs: 4, md: 6 }} mb={{ xs: 4, md: 6 }}>
          <Grid container pt={{ xs: 4, sm: 5, md: 6, lg: 7 }} className='normal-box' spacing={4}>
            {services?.slice(0, 3).map((service, index) => (
              <Grid item key={index}>
                <Typography variant='body2' fontWeight={400}>
                  {service.name}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Box className='hover-box'>
            {description && (
              <CardDescription variant='body2' mt={4}>
                {description}
              </CardDescription>
            )}

            {technologiesList && (
              <CardTechnologies variant='body2' fontSize={14} fontWeight={400} pt={4} mr={8}>
                {technologiesList}
              </CardTechnologies>
            )}

            <ArrowIcon />
          </Box>
        </Box>
      </CaseCardWrapper>
    </Link>
  )
}

export default CaseCard
