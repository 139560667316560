import { styled } from '@mui/material'

interface IFeatureItemWrapperProps {
  hasSeparator: boolean
}

export const FeatureItemWrapper = styled('div')<IFeatureItemWrapperProps>(({ theme, hasSeparator }) => ({
  'listStyle': 'none',
  'display': 'flex',
  'alignItems': 'center',
  'minHeight': 70,
  'width': '100%',
  'paddingTop': 4,
  'paddingBottom': 4,
  'boxSizing': 'border-box',

  ...(hasSeparator && {
    borderTop: '1px solid',
    borderTopColor: theme.palette.brand.platinumGray,
  }),
}))

export const PriceWrapper = styled('div')(({ theme }) => ({
  'position': 'relative',
  'backgroundColor': theme.palette.primary.main,
  'color': theme.palette.common.white,
  'paddingLeft': theme.spacing(6),
  'width': '80%',
  'height': 34,
  'display': 'flex',
  'alignItems': 'center',

  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '100%',
    height: '100%',
    boxSizing: 'border-box',
    borderTop: '17px solid',
    borderTopColor: theme.palette.primary.main,
    borderBottom: '17px solid',
    borderBottomColor: theme.palette.primary.main,
    borderRight: '17px solid',
    borderRightColor: 'transparent',
  },
}))

export const FeatureCheckIcon = styled('div')(({ theme }) => ({
  'backgroundColor': '#F7EDED',
  'borderRadius': '50%',
  'width': 32,
  'height': 32,
  'margin': '0 10px 0 0',
  'position': 'relative',
  'flexShrink': 0,

  '&::after': {
    boxSizing: 'content-box',
    content: '""',
    position: 'absolute',
    top: 9,
    left: 9,
    width: 11,
    height: 6,
    borderBottom: '3px solid',
    borderLeft: '3px solid',
    borderColor: theme.palette.primary.main,
    transform: 'rotate(-45deg)',
  },
}))

export const CollapsedBlanked = styled('div')(({ theme }) => ({
  position: 'relative',
  height: 120,
  marginTop: -115,
  marginLeft: -3,
  marginRight: -3,
  background: `linear-gradient(0deg, ${theme.palette.background.default} 30%, rgba(0,0,0,0) 100%)`,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',

  color: theme.palette.primary.main,
}))
