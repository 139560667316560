import React, { useLayoutEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { IStrapiColor, IStrapiImage, IStrapiQuoteImagePosition, IStrapiSection } from '../../../types/strapi'
import Image, { IImageMaxSize } from '../../image'
import { Stack } from '@mui/material'
import { Divider } from './QuotesSection.styled'
import QuoteDefaultIcon from '../../../icons/QuoteDefault'
import { useSectionsContext } from '../../../hooks/useSectionsContext'

export interface IQuotesSectionData extends IStrapiSection {
  quote: string
  author?: string
  authorJobTitle?: string
  authorLogo?: IStrapiImage
  image?: IStrapiImage
  imagePosition: IStrapiQuoteImagePosition
  dividerColor?: IStrapiColor | 'none'
}

export interface IQuotesSectionProps {
  data: IQuotesSectionData
}

const QuotesSection: React.FC<IQuotesSectionProps> = ({ data }) => {
  const { quote, author, authorJobTitle, authorLogo, image, imagePosition, dividerColor } = data

  const { setPrevSectionModifiers } = useSectionsContext()

  const isFloatingImage = imagePosition === IStrapiQuoteImagePosition.FLOATING

  const halfImageHeight = image ? (+image.height / 2) : 0

  const floatingProps = image && isFloatingImage ? {
    position: 'absolute',
    top: 0,
    transform: `translateY(-${halfImageHeight}px)`,
  } : {}

  useLayoutEffect(() => {
    setPrevSectionModifiers({ extraBottomPadding: halfImageHeight }, data)
  }, [ setPrevSectionModifiers, halfImageHeight, data ])

  return (
    <Box maxWidth={isFloatingImage ? '85%' : 750} m='auto'>
      <Stack alignItems='center' spacing={4} position='relative' pt={isFloatingImage ? halfImageHeight + 'px' : 0}>
        {image ? (
          <Image image={image} sx={floatingProps} />
        ) : (
          <QuoteDefaultIcon sx={{ width: 56, height: 45 }} />
        )}

        <Typography pt={{ xs: isFloatingImage ? 0 : 4, md: 4 }} textAlign='center' color='text.primary' variant='body1'>
          {quote}
        </Typography>

        {(author || authorJobTitle || authorLogo) && (dividerColor !== 'none') && (
          <Divider color={dividerColor} />
        )}

        {authorLogo && (
          <Stack width='100%' alignItems='center'>
            <Image image={authorLogo} sx={{ height: 30 }} />
          </Stack>
        )}

        {author && (
          <Typography color='text.primary' textAlign='center' fontWeight={600}>
            {author}
          </Typography>
        )}

        {authorJobTitle && (
          <Typography color='text.secondary' fontWeight={400}>
            {authorJobTitle}
          </Typography>
        )}
      </Stack>
    </Box>
  )
}

export default QuotesSection
