import { Stack } from '@mui/material'
import PriceCard from '../../../../section-card/price-card/PriceCard'
import { useState } from 'react'
import { IStrapiPriceCard } from '../../../../../types/strapi'

export interface IMobilePriceCardsProps {
  priceCards: Array<IStrapiPriceCard>
}

const MobilePriceCards = (props: IMobilePriceCardsProps) => {
  const { priceCards } = props

  const [ expandedItem, setExpandedItem ] = useState<null | number>(null)

  const toggleCollapsed = (itemIndex: number) => () => {
    setExpandedItem(prev => prev === itemIndex ? null : itemIndex)
  }

  return (
    <Stack spacing={6}>
      {priceCards.map((card, index) => (
        <PriceCard
          {...card}
          key={index}
          collapsed={expandedItem !== index}
          onClick={toggleCollapsed(index)}
          showCollapsedArrow
        />
      ))}
    </Stack>
  )
}

export default MobilePriceCards
