import React from 'react'
import { IStrapiButton, IStrapiButtonsAlignment, IStrapiFlexDirection, IStrapiSection } from '../../../types/strapi'
import { useIsMobile } from '../../../hooks/useIsMobile'
import Button from '../../button/Button'
import { Stack } from '@mui/material'
import castButtonsAlignment from '../../../utils/castButtonsAlignment'

export interface IButtonsSectionData extends IStrapiSection {
  buttons: Array<IStrapiButton>
  mobileFullWidth: boolean
  alignment: IStrapiButtonsAlignment
  direction: IStrapiFlexDirection
}

export interface IButtonsSectionProps {
  data: IButtonsSectionData
}

const ButtonsSection: React.FC<IButtonsSectionProps> = ({ data }) => {
  const {
    buttons,
    direction = IStrapiFlexDirection.ROW,
    alignment = IStrapiButtonsAlignment.LEFT,
    mobileFullWidth = false,
  } = data

  const isSmallMobile = useIsMobile('sm')

  const buttonsAlignment = {
    [isSmallMobile || direction === IStrapiFlexDirection.COLUMN ? 'alignItems' : 'justifyContent']: castButtonsAlignment(alignment),
  }

  return (
    <Stack spacing={isSmallMobile ? 3 : 5} direction={isSmallMobile ? 'column' : direction} {...buttonsAlignment}>
      {buttons.map((button, index) => (
        <Button key={index} {...button} fullWidth={mobileFullWidth} {...!isSmallMobile && { fullWidth: false }} />
      ))}
    </Stack>
  )
}

export default ButtonsSection
