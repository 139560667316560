import React from 'react'
import { IStrapiBaseLink, IStrapiListItem, IStrapiListStyle } from '../../../types/strapi'
import { Typography } from '@mui/material'
import Link from '../../link'

interface IListItemContent extends IStrapiListItem {
  listStyle?: IStrapiListStyle
}

export const ListItemContent = (props: IListItemContent) => {
  const { title, content, link, listStyle } = props

  const variant = link && listStyle === IStrapiListStyle.LINK ? 'link' : 'body1'

  const fontWeight = link && listStyle === IStrapiListStyle.LINK ? 400 : 300

  const color = link && listStyle === IStrapiListStyle.LINK ? 'primary.main' : 'text.secondary'

  return (
    <ListItemContentWrapper link={link}>
      {title && (
        <Typography color={color} fontWeight={500} variant={variant}>
          {title}
        </Typography>
      )}
      {content && (
        <Typography color={color} mr='auto' variant={variant} fontWeight={fontWeight}>
          {content}
        </Typography>
      )}
    </ListItemContentWrapper>
  )
}

interface IListItemContentWrapperProps extends React.PropsWithChildren {
  link?: IStrapiBaseLink
}

const ListItemContentWrapper = (props: IListItemContentWrapperProps) => {
  const { link, children } = props

  if (!link) {
    return (
      <span>
        {children}
      </span>
    )
  }

  return (
    <Link {...link}>
      {children}
    </Link>
  )
}

export default ListItemContent
