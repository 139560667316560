import { Button, Popper } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme from '../../../../theme'

export const FilterButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  padding: 12,

  [theme.breakpoints.up('md')]: {
    fontSize: 18,
    padding: 16,
  },
}))

interface IOptionButtonProps {
  selected: boolean
}

export const OptionButton = styled(Button, { shouldForwardProp: (props) => props !== 'selected' })<IOptionButtonProps>(({ theme, selected = false }) => ({
  'padding': '8px 12px',
  'boxShadow': 'none',
  'fontSize': 16,
  'fontWeight': 400,
  'lineHeight': '25px',
  'borderRadius': 8,

  '&:hover': {
    backgroundColor: theme.palette.tertiary.main,
    boxShadow: 'none',
    borderColor: theme.palette.text.primary,
    color: 'inherit',
  },

  ...(selected && {
    backgroundColor: theme.palette.brand.charcoalGray,
    color: theme.palette.common.white,
  }),

  [theme.breakpoints.up('md')]: {
    'fontSize': 18,
  },
}))

export const FilterMenu = styled(Popper)(() => ({
  zIndex: 1,
  width: '100%',
  maxWidth: '100%',
  left: 0,
  right: 0,
  background: theme.palette.background.default,
  boxShadow: '0px 4.797px 1.854px 0px rgba(0, 0, 0, 0.01), 0px 11.153px 4.31px 0px rgba(0, 0, 0, 0.01), 0px 20.027px 7.74px 0px rgba(0, 0, 0, 0.01), 0px 33.238px 12.846px 0px rgba(0, 0, 0, 0.01), 0px 54.759px 21.163px 0px rgba(0, 0, 0, 0.01), 0px 95.702px 36.986px 0px rgba(0, 0, 0, 0.01), 0px 207px 80px 0px rgba(0, 0, 0, 0.02)',
}))
