import { IStrapiSection, IStrapiSocial, IStrapiSocialProps, IStrapiTitle } from '../../../types/strapi'
import Title from '../../title/Title'
import SocialsBlock from '../../socials-block/SocialsBlock'
import { Stack } from '@mui/material'
import { useIsMobile } from '../../../hooks/useIsMobile'

export interface ISocialsSectionData extends IStrapiSection {
  title: IStrapiTitle
  socialProps: IStrapiSocialProps
  socials: Array<IStrapiSocial>
}

export interface ISocialsSectionProps {
  data: ISocialsSectionData
}

const SocialsSection = (props: ISocialsSectionProps) => {
  const { title, socialProps, socials } = props.data

  const isMobile = useIsMobile('md')

  return (
    <Stack direction={isMobile ? 'column' : 'row'} alignItems='center' justifyContent='space-between' spacing={8}>
      <Title title={title} flexShrink={0} component='p' />
      <SocialsBlock socialProps={socialProps} socials={socials} />
    </Stack>
  )
}

export default SocialsSection
