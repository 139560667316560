import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TagsRowWrapper = styled('div')(({ theme }) => ({
  '&:not(last-of-type)': {
    marginBottom: 20,
  },

  'maxWidth': 'var(--fixed100vw, 100vw)',
  'overflow': 'hidden',

  [theme.breakpoints.down('sm')]: {
    '&:not(last-of-type)': {
      marginBottom: 0,
    },

    'marginLeft': -10,
    'marginRight': -10,
  },
}))

export const TagsBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: 'var(--fixed100vw, 100vw)',
  maxWidth: 'var(--fixed100vw, 100vw)',
  left: '50%',
  marginLeft: '-50%',
  backgroundImage: 'url(/images/price-tag-bg.avif)',
  backgroundPosition: 'top center',
  backgroundRepeat: 'repeat-x',
  zIndex: -1,

  [theme.breakpoints.down('sm')]: {
    transform: 'scaleY(0.85)',
    transformOrigin: 'top center',
  },
}))

export const TagWrapper = styled('div')(({ theme }) => ({
  margin: '0 auto',
  width: 184,

  [theme.breakpoints.down('sm')]: {
    transform: 'scale(0.85)',
    transformOrigin: 'top center',
  },
}))

export const TagBody = styled('div')(() => ({
  backgroundColor: '#F5F4F4',
  paddingBottom: 20,
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
}))

export const TagPrice = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  borderRadius: '0px 8px 8px 0px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.brand.brandedRed,
}))
