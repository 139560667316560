import React, { useRef } from 'react'
import { CardWrapper } from '../SectionCard.styled'
import { IStrapiLicenseCard } from '../../../types/strapi'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '../../button'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { useSizeObserver } from '../../../hooks/useSizeObserver'
import ImageLink from '../../image-link'

export interface ILicenseCardProps extends IStrapiLicenseCard {}

const LicenseCard: React.FC<ILicenseCardProps> = (props) => {
  const { content, backgroundColor, price, button, image, link } = props

  const cardRef = useRef(null)
  const isMobileSize = useSizeObserver(cardRef, 450)
  const isGridLayout = !isMobileSize && Boolean(image) && Boolean(price)

  return (
    <CardWrapper background={{ color: backgroundColor }}>
      <Grid container spacing={2} columns={11} ref={cardRef}>
        <Grid item xs={isGridLayout ? 5 : 11} order={isGridLayout ? 0 : 2}>
          <ImageLink image={image} link={link} />

          {price && (
            <Typography variant='body2' mt={4} fontSize={28} fontWeight={600} color='primary.main' sx={{ textAlign: 'center' }}>
              {price}
            </Typography>
          )}

          {button && (
            <Button {...button} sx={{ mt: 4, ...(isGridLayout && { display: 'flex', mx: 'auto' }) }} fullWidth={isMobileSize} />
          )}
        </Grid>

        <Grid item xs={isGridLayout ? 6 : 11}>
          <Wysiwyg data={content} />
        </Grid>
      </Grid>
    </CardWrapper>
  )
}

export default LicenseCard
