import React from 'react'
import { IStrapiFormField, IStrapiFormProps } from '../../types/strapi'

export interface IFormContext {
  formProps: IStrapiFormProps
  formFields: Array<IStrapiFormField>
  formAgreement: { data: string }
}

export const FormContext = React.createContext<IFormContext>({} as IFormContext)

export type IFormContextProviderProps = React.PropsWithChildren & IFormContext

export const FormContextProvider = (props: IFormContextProviderProps) => {
  const { formProps, formAgreement, formFields } = props
  return (
    <FormContext.Provider value={{ formProps, formAgreement, formFields }}>
      {props.children}
    </FormContext.Provider>
  )
}
