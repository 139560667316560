import { IStrapiGridSpacing } from '../types/strapi'
import { GridProps } from '@mui/material/Grid'

const castStrapiColumnSpacing = (spacing?: IStrapiGridSpacing): GridProps['spacing'] => {
  switch (spacing) {
    case IStrapiGridSpacing.OFF:
      return { xs: 0 }

    case IStrapiGridSpacing.SMALL:
      return { xs: 5 }

    case IStrapiGridSpacing.NORMAL:
      return { xs: 5, md: 10 }

    case IStrapiGridSpacing.LARGE:
      return { xs: 10, md: 15, lg: 20 }

    case IStrapiGridSpacing.EXTRA_LARGE:
      return { xs: 10, md: 20, lg: 30 }

    default:
      // eslint-disable-next-line no-console
      console.log('Unknown column spacing prop', spacing)
      return { xs: 10, md: 15, lg: 20 }
  }
}

export const castStrapiRowSpacing = (spacing?: IStrapiGridSpacing): GridProps['spacing'] => {
  switch (spacing) {
    case IStrapiGridSpacing.OFF:
      return { xs: 0 }

    case IStrapiGridSpacing.SMALL:
      return { xs: 5 }

    case IStrapiGridSpacing.NORMAL:
      return { xs: 5, md: 10 }

    default:
      // eslint-disable-next-line no-console
      console.log('Unknown row spacing prop', spacing)
      return { xs: 5 }
  }
}

export default castStrapiColumnSpacing
