import React from 'react'
import { CardWrapper } from '../SectionCard.styled'
import { IStrapiActionCard } from '../../../types/strapi'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ChevronDownIcon from '../../../icons/ChevronDown'

export interface IRoleCardProps extends IStrapiActionCard {}

const RoleCard: React.FC<IRoleCardProps> = (props) => {
  const { content, details } = props

  return (
    <CardWrapper>
      <Wysiwyg data={content} />

      <Accordion>
        <AccordionSummary sx={{ 'p': 0, '& .MuiAccordionSummary-content': { flexGrow: 0 }, '& .MuiAccordionSummary-expandIconWrapper': { marginRight: 'auto' } }} expandIcon={<ChevronDownIcon color='primary' sx={{ height: 24 }} />}>
          <Typography variant='body2' color='primary.main' fontWeight={500}>Benefits</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Wysiwyg data={details} />
        </AccordionDetails>
      </Accordion>
    </CardWrapper>
  )
}

export default RoleCard
