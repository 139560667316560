import React from 'react'
import { IStrapiBlogPost, IStrapiBlogTemplate } from '../../../../types/strapi'
import CarouselSection, { ICarouselSectionData } from '..'
import BlogSlide from '../../../carousel-slide/blog-slide/BlogSlide'
import { useLatestBlogPosts } from '../../../../hooks/useLatestBlogPosts'

export interface IBlogCarouselSectionData extends ICarouselSectionData {
  posts: Array<IStrapiBlogPost>
  template?: IStrapiBlogTemplate
}

export interface IBlogCarouselSectionProps {
  data: IBlogCarouselSectionData
}

const BlogCarouselSection: React.FC<IBlogCarouselSectionProps> = ({ data }) => {
  const { posts, template } = data

  const latestPosts = useLatestBlogPosts(3)

  switch (template) {
    case IStrapiBlogTemplate.LATEST:
      return <CarouselSection data={data} SlideComponent={BlogSlide} slides={latestPosts} />

    case IStrapiBlogTemplate.MANUAL:
      return <CarouselSection data={data} SlideComponent={BlogSlide} slides={posts} />

    default:
      // eslint-disable-next-line no-console
      console.log('Unknown blog section template:', template)
      return <CarouselSection data={data} SlideComponent={BlogSlide} slides={posts} />

  }
}

export default BlogCarouselSection
