import React from 'react'
import { Box, styled } from '@mui/material'
import { castCardPadding } from '../SectionCard.styled'
import { useCardStyleContext } from '../../../hooks/useCardStyleContext'

export const SlideDownWrapper = styled('div')(() => {
  const { rounded } = useCardStyleContext()

  return {
    'overflow': 'hidden',
    'position': 'relative',
    'zIndex': 1,
    'borderRadius': rounded ? '8px' : 0,
    'boxShadow': '0px 40px 80px 0px rgba(0, 0, 0, 0.25)',
    'height': '100%',

    '& > div': {
      transition: 'transform ease-in-out 0.6s',
    },

    '&:hover > div': {
      transform: 'translateY(100%)',
    },
  }
})

export const DetailsWrapper = (props: React.PropsWithChildren) => {
  const { backgroundColor, rounded, cardPadding } = useCardStyleContext()

  return (
    <Box sx={{
      boxSizing: 'border-box',
      position: 'absolute',
      border: '1px solid',
      zIndex: 1,
      borderColor: 'common.white',
      borderRadius: rounded ? '8px' : 0,
      height: '100%',
      width: '100%',
      top: '-100%',
      backgroundColor: backgroundColor,
      ...castCardPadding(cardPadding),
    }}
    >
      {props.children}
    </Box>
  )
}
