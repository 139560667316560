import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const FlipCardInner = styled(Box)(() => ({
  'position': 'relative',
  'width': '100%',
  'transition': 'transform ease-in-out 0.6s',
  'transformStyle': 'preserve-3d',
  'height': '100%',

  '&:hover': {
    transform: 'perspective(1000px) rotateY(180deg)',
  },
}))

export const FlipCardFront = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  minHeight: '100%',
  backfaceVisibility: 'hidden',
}))

export const FlipCardBack = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  minHeight: '100%',
  backfaceVisibility: 'hidden',
  transform: 'rotateY(180deg)',
}))
