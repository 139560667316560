import { styled } from '@mui/material/styles'
import { IStrapiFAQTemplate } from '../../../types/strapi'

export interface IExpandIconProps {
  isExpanded: boolean
  template: IStrapiFAQTemplate
}

export const ExpandIcon = styled('div', { shouldForwardProp: (props) => props !== 'isExpanded' })<IExpandIconProps>(({ theme, isExpanded, template = IStrapiFAQTemplate.BRAND }) => {
  const background = template === IStrapiFAQTemplate.NFT
    ? theme.palette.brand.nftMain
    : theme.palette.brand.primaryGradient

  return {
    'width': 32,
    'height': 32,
    'position': 'relative',

    '&:before': {
      content: '""',
      position: 'absolute',
      width: 32,
      height: 3,
      top: '50%',
      transform: 'translateY(-50%)',
      borderRadius: 1,
      background: background,
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      width: 3,
      height: 32,
      left: '50%',
      transform: 'translateX(-50%)',
      borderRadius: 1,
      background: background,
      transition: 'transform ease-in-out .2s',

      ...(isExpanded && {
        transform: 'translateX(-50%) scale(0)',
      }),
    },
  }
})
