import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { IStrapiImage, IStrapiPadding, IStrapiSection } from '../../../types/strapi'
import Image, { IImageMaxSize } from '../../image'
import FullWidthContainer from '../../full-width-container/FullWidthContainer'
import useIsLazySection from '../../../hooks/useIsLazySection'
import { useIsMobile } from '../../../hooks/useIsMobile'

export interface IImageSectionData extends IStrapiSection {
  image: IStrapiImage
  padding: IStrapiPadding
  mobileFullWidth?: boolean
}

export interface IImageSectionProps {
  data: IImageSectionData
}

const ImageSection: React.FC<IImageSectionProps> = ({ data }) => {
  const { options, image, padding, mobileFullWidth } = data

  const isLazy = useIsLazySection()

  const isMobileLayout = useIsMobile('md')

  const isFullWidthWrapper = Boolean(isMobileLayout && mobileFullWidth)

  return (
    <FullWidthContainer
      bgcolor={options?.theme?.backgroundColor}
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100%'
      disable={!isFullWidthWrapper}
    >
      <Box px={castImagePadding(padding)} boxSizing='border-box' width='100%' display='flex' justifyContent='center' alignItems='center'>
        <Image image={image} maxSize={IImageMaxSize.CONTAINER} isLazy={isLazy} />
      </Box>
    </FullWidthContainer>
  )
}

export default ImageSection

export const castImagePadding = (padding: IStrapiPadding): BoxProps['px'] => {
  switch (padding) {
    case IStrapiPadding.EXTRA_LARGE:
      return '30%'

    case IStrapiPadding.LARGE:
      return '22%'

    case IStrapiPadding.NORMAL:
      return '15%'

    case IStrapiPadding.SMALL:
      return '8%'

    case IStrapiPadding.OFF:
      return 0

    default:
      // eslint-disable-next-line no-console
      console.log('Unknown image padding prop', padding)
      return 0
  }
}
