import React from 'react'
import { Box, Grid, GridProps } from '@mui/material'
import CaseCard, { ICaseCardProps } from '../../../case-card'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import { isClient } from '../../../../utils/isClient'

const Carousel = React.lazy(() => import('../../../carousel/Carousel'))

export interface IHomeGridCasesCardsProps {
  cases: Array<ICaseCardProps>
}

const CASES_IN_GROUP = 5

const HomeGridCasesCards = (props: IHomeGridCasesCardsProps) => {
  const { cases } = props

  const isMobile = useIsMobile('md')

  if (isMobile) {
    const CaseCardSkeleton = () => (
      <Box py={3}>
        <CaseCard {...cases[0]} />
      </Box>
    )

    return (
      <>
        {isClient() ? (
          <React.Suspense fallback={<CaseCardSkeleton />}>
            <Carousel
              slidesPerView={1}
              items={cases}
              SlideComponent={CaseCard as React.FC<unknown>}
            />
          </React.Suspense>
        ) : (
          <CaseCardSkeleton />
        )}
      </>
    )
  }

  const casesGroups = cases.reduce((groups: Array<Array<ICaseCardProps>>, _, index) => {
    if (index % CASES_IN_GROUP === 0) {
      groups.push(cases.slice(index, index + CASES_IN_GROUP))
    }
    return groups
  }, [])

  return (
    <>
      {casesGroups.map((group, index) => (
        <Grid container key={index} spacing={4} py={2}>
          {group.map((caseCard, index) => (
            <Grid item key={index} {...castHomeGridSize(index)}>
              <CaseCard {...caseCard} />
            </Grid>
          ))}
        </Grid>
      ))}
    </>
  )
}

export default HomeGridCasesCards

export const castHomeGridSize = (index: number): GridProps => {
  switch (index) {
    case 0:
      return { xs: 12, lg: 5 }
    case 1:
      return { xs: 12, md: 6, lg: 7 }
    case 2:
      return { xs: 12, md: 6, lg: 6 }
    case 3:
    case 4:
    default:
      return { xs: 12, md: 6, lg: 3 }
  }
}
