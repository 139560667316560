import React from 'react'
import Typography from '@mui/material/Typography'
import { IStrapiCarouselProps, IStrapiSection, IStrapiTitle } from '../../../types/strapi'
import FullWidthContainer from '../../full-width-container/FullWidthContainer'
import { ICarouselProps } from '../../carousel/Carousel'
import Title from '../../title/Title'
import { isClient } from '../../../utils/isClient'
import { Box } from '@mui/material'

const Carousel = React.lazy(() => import('../../carousel/Carousel'))

export interface ICarouselSectionData extends IStrapiSection {
  title?: IStrapiTitle
  description?: string
  carouselProps: IStrapiCarouselProps
}

export type ICarouselSectionProps<T> = Partial<ICarouselProps<T>> & {
  data: ICarouselSectionData
  SlideComponent: React.FC<T>
  slides: Array<T>
}

const CarouselSection = <T,>(props: ICarouselSectionProps<T>) => {
  const { data, slides, SlideComponent, ...rest } = props
  const { title, description, carouselProps, options } = data

  const isFullWidthCarousel = Boolean(carouselProps?.creeping)

  const CarouselSkeleton = () => (
    <Box py={3}>
      <SlideComponent {...slides[0]} as T />
    </Box>
  )

  return (
    <>
      <Title title={title} hasSpacing />

      {description && (
        <Typography mb={{ xs: 6, md: 8, maxWidth: 900 }} color='text.secondary'>
          {description}
        </Typography>
      )}

      {Boolean(slides?.length) && (
        <FullWidthContainer bgcolor={options?.theme?.backgroundColor} disable={!isFullWidthCarousel}>
          {isClient() ? (
            <React.Suspense fallback={<CarouselSkeleton />}>
              <Carousel
                {...carouselProps}
                {...rest}
                items={slides}
                SlideComponent={SlideComponent as React.FC<unknown>}
              />
            </React.Suspense>
          ) : (
            <CarouselSkeleton />
          )}
        </FullWidthContainer>
      )}
    </>
  )
}

export default CarouselSection
