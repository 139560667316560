import { IStrapiWysiwyg } from '../../types/strapi'
import { SxProps, Typography } from '@mui/material'
import useIsLazySection from '../../hooks/useIsLazySection'

export interface IWysiwygProps {
  data?: IStrapiWysiwyg
  sx?: SxProps
}

const Wysiwyg = (props: IWysiwygProps) => {
  const { data, sx } = props

  const isLazy = useIsLazySection()

  if (!data?.data) {
    return null
  }

  const { fields: { fixedHtml }, images } = data.data.childMarkdownRemark

  if (!fixedHtml) {
    return null
  }

  if (!images) {
    return (
      <Typography variant='wysiwyg' dangerouslySetInnerHTML={{ __html: fixedHtml }} sx={sx} />
    )
  }

  const html = images.reduce(
    (html: string, { url, publicURL }) => html.replace(url, publicURL),
    isLazy ? fixedHtml.replaceAll('<img', `<img loading="lazy"`) : fixedHtml,
  )

  return (
    <Typography variant='wysiwyg' dangerouslySetInnerHTML={{ __html: html }} sx={sx} />
  )
}

export default Wysiwyg
