import React from 'react'
import { IStrapiCardAlignment, IStrapiCardStyle, IStrapiPadding } from '../../types/strapi'

export const CardStyleContext = React.createContext<IStrapiCardStyle>({
  rounded: true,
  shadow: true,
  hoverable: true,
  alignment: IStrapiCardAlignment.LEFT,
  cardPadding: IStrapiPadding.NORMAL,
})

export interface ICardStyleProviderProps extends React.PropsWithChildren {
  cardStyle: IStrapiCardStyle
}

const CardStyleProvider = (props: ICardStyleProviderProps) => {
  const { cardStyle, children } = props

  return (
    <CardStyleContext.Provider value={cardStyle}>
      {children}
    </CardStyleContext.Provider>
  )
}

export default CardStyleProvider
