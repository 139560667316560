import { Box, Theme, Typography, styled } from '@mui/material'
import React from 'react'
import { IStrapiPriceLabelCardsTemplate } from '../../../types/strapi'

export const CardPrice = (props: React.PropsWithChildren) => {
  return (
    <Box mt='200px' px={5} pt={1} pb={7}>
      <Typography fontSize={48} lineHeight='48px' fontWeight={600}>
        <Typography fontSize={16} fontWeight={400} display='inline' component='span' mr={1} sx={{ verticalAlign: 'top' }}>$</Typography>
        {props.children}
      </Typography>
      <Typography fontSize={16} fontWeight={400} textAlign='center'>/ monthly</Typography>
    </Box>
  )
}

export const PriceCardHeader = styled('div', { shouldForwardProp: prop => prop !== 'template' })<{template?: IStrapiPriceLabelCardsTemplate}>(({ theme, template }) => {
  const backgroundColor = castPriceLabelCardTemplate(template, theme)

  return {
    'position': 'absolute',
    'top': 0,
    'left': 0,
    'width': '100%',
    'height': 200,

    'boxSizing': 'border-box',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'padding': 24,
    'backgroundColor': backgroundColor,

    '&& *': {
      color: theme.palette.getContrastText(backgroundColor),
    },
  }
})

export const CardLabel = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 30,
  top: 30,
  transform: 'translateX(50%) rotate(45deg)',
  transformOrigin: 'top center',
  width: '100%',
  boxSizing: 'border-box',

  fontSize: 14,
  lineHeight: '26px',
  fontWeight: 600,
  textAlign: 'center',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,

  zIndex: 1,
}))

const castPriceLabelCardTemplate = (template?: IStrapiPriceLabelCardsTemplate, theme: Theme) => {
  switch(template) {
    case IStrapiPriceLabelCardsTemplate.CLOUD_HOSTING:
      return theme.palette.custom.platinumBlue

    case IStrapiPriceLabelCardsTemplate.VPS_HOSTING:
      return theme.palette.brand.charcoalGray

    default:
      // eslint-disable-next-line no-console
      console.log('Unknown cardPrice template:', template)
      return theme.palette.background.paper
  }
}
