import React, { useLayoutEffect, useRef, useState } from 'react'
import { CardWrapper } from '../SectionCard.styled'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { IStrapiActionCard } from '../../../types/strapi'
import { FlipCardBack, FlipCardFront, FlipCardInner } from './FlipCard.styled'

export interface IFlipCardProps extends IStrapiActionCard {}

const FlipCard: React.FC<IFlipCardProps> = (props) => {
  const { content, details, backgroundColor, link } = props

  const [ cardHeight, setCardHeight ] = useState(0)
  const frontRef = useRef<HTMLDivElement>(null)
  const backRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const front = frontRef?.current
    const back = backRef?.current

    if (!front || !back) {
      return
    }

    const frontHeight = front.getBoundingClientRect().height
    const backHeight = back.getBoundingClientRect().height
    setCardHeight(Math.max(frontHeight, backHeight))
  }, [])

  return (
    <FlipCardInner sx={{ cursor: link ? 'pointer' : 'default', ...(cardHeight && { minHeight: cardHeight }) }}>
      <FlipCardFront sx={cardHeight ? { height: cardHeight } : {}}>
        <CardWrapper background={{ color: backgroundColor }} ref={frontRef} link={link}>
          <Wysiwyg data={content} sx={{ width: '100%', my: 'auto' }} />
        </CardWrapper>
      </FlipCardFront>

      <FlipCardBack sx={cardHeight ? { height: cardHeight } : {}}>
        <CardWrapper background={{ color: backgroundColor }} ref={backRef} link={link}>
          <Wysiwyg data={details} sx={{ width: '100%', my: 'auto' }} />
        </CardWrapper>
      </FlipCardBack>
    </FlipCardInner>
  )
}

export default FlipCard
