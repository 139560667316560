import { IStrapiStepItem } from '../../../../types/strapi'
import { Box, Grid } from '@mui/material'
import Wysiwyg from '../../../wysiwyg/Wysiwyg'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import Image from '../../../image'
import React from 'react'
import { MobileWrapLine, RowWrapArrow, StepArrow } from './CardArrowSteps.styled'

export interface ICardArrowStepsProps {
  steps: Array<IStrapiStepItem>
}

const CardArrowSteps = (props: ICardArrowStepsProps) => {
  const { steps } = props

  const isMobile = useIsMobile('md')
  const isTablet = useIsMobile('lg')

  const cardsInRowLg = Math.min(steps.length, 3)
  const cardsInRowMd = Math.min(steps.length, 2)
  const cardsInRow = isMobile ? 1 : isTablet ? cardsInRowMd : cardsInRowLg

  const rowsSteps = steps.reduce((rows: Array<Array<IStrapiStepItem>>, _, index) => {
    if (index % cardsInRow === 0) {
      rows.push(steps.slice(index, index + cardsInRow))
    }
    return rows
  }, [])

  return (
    <>
      {rowsSteps.map((row, rowIndex) => (
        <React.Fragment key={rowIndex}>
          {!isMobile && Boolean(rowIndex) && (
            <Box ml={10} mr={24}>
              <RowWrapArrow />
            </Box>
          )}

          {isMobile && Boolean(rowIndex) && (
            <MobileWrapLine />
          )}

          <Grid container alignItems='center' spacing={isMobile ? 0 : 10} key={rowIndex} py={isMobile ? 0 : 4} flexWrap={isMobile ? 'wrap' : 'nowrap'}>
            {row.map((step, stepIndex) => (
              <React.Fragment key={stepIndex}>
                {!isMobile && Boolean(stepIndex) && (
                  <Grid item sm={12} md flexGrow={1}>
                    <StepArrow />
                  </Grid>
                )}

                <Grid item sm={12} md='auto' display={isMobile ? 'flex' : 'block'} alignItems='center'>
                  <Image image={step.image} sx={{ width: isMobile ? 40 : 48 + 'px', mb: isMobile ? 0 : 4, mr: isMobile ? 4 : 0 }} />
                  <Wysiwyg data={step.content} />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </React.Fragment>
      ))}
    </>
  )
}

export default CardArrowSteps
