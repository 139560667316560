import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { EmailAddressLink } from './MainFormSection.styles'
import { IStrapiFormField, IStrapiFormProps, IStrapiSection, IStrapiSocialsBlock, IStrapiTitle } from '../../../types/strapi'
import SocialsBlock from '../../socials-block/SocialsBlock'
import SectionLayout from '../../../layouts/section-layout/SectionLayout'
import useLazyWrapper from '../../../hooks/useLazyWrapper'
import Title from '../../title/Title'
import { FormContextProvider } from '../../../contexts/form-context/FromContext'
import { LazyProvider } from '../../../contexts/lazy-provider/LazyProvider'

const MainForm = React.lazy(() => import('../../forms/main-form/MainForm'))

export interface IMainFormSectionData extends IStrapiSection {
  description: string
  title?: IStrapiTitle
  socials?: IStrapiSocialsBlock
  mail?: string
  formProps: IStrapiFormProps
  formFields: Array<IStrapiFormField>
  formAgreement: { data: string }
}

export interface IMainFormSectionProps {
  data: IMainFormSectionData
}

const MainForSection: React.FC<IMainFormSectionProps> = ({ data }) => {

  const lazyMainForm = useLazyWrapper(<MainForm />)

  if (!data) {
    return null
  }

  const { description, title, socials, mail, options } = data

  return (
    <LazyProvider isLazy>
      <FormContextProvider {...data}>
        <SectionLayout options={options}>
          <Grid container sx={{ px: { xs: 2, md: 6, lg: 8, xl: 10 }, pt: { xs: 0, md: 5, lg: 10 }, backdropFilter: 'blur(20px)', borderRadius: '10px' }}>
            <Grid item xs={12} md px={{ xs: 3, md: 6, lg: 10, xl: 15 }} py={{ xs: 6, md: 10, lg: 15 }}>
              <Title title={title} hasSpacing component='p' />

              <Typography fontSize={{ xs: 14, sm: 16, md: 18 }} fontWeight={400} lineHeight={1.5} mb={4} color='text.secondary'>
                {description}
              </Typography>

              {mail && (
                <address>
                  <EmailAddressLink
                    href={`mailto:${mail}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {mail}
                  </EmailAddressLink>
                </address>
              )}

              {socials && (
                <SocialsBlock {...socials} />
              )}
            </Grid>

            <Grid item xs={12} md px={{ xs: 3, md: 6, lg: 10, xl: 15 }} py={{ xs: 6, md: 10, lg: 15 }}>
              {lazyMainForm}
            </Grid>
          </Grid>
        </SectionLayout>
      </FormContextProvider>
    </LazyProvider>
  )
}

export default MainForSection
