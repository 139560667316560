import { GridProps } from '@mui/material/Grid'

export interface IGridSize {
  xs: GridProps['xs']
  md: GridProps['md']
  lg: GridProps['lg']
}

const getGridItemSize = (itemsInRow: number): Pick<GridProps, 'xs' | 'md' | 'lg' | 'xl'> => {

  const cardsInRowFixed = itemsInRow ?? 3

  switch (cardsInRowFixed) {
    case 0:
      return { xs: false }

    case 1:
      return { xs: 12 }

    case 2:
    case 3:
    case 4:
      return { xs: 12, md: 6, lg: 12 / cardsInRowFixed }

    default:
      return {
        xs: 12 / Math.ceil(cardsInRowFixed / 4),
        md: 12 / Math.ceil(cardsInRowFixed / 1.9),
        lg: 12 / Math.ceil(cardsInRowFixed * 2.26 / 3),
        xl: 12 / cardsInRowFixed,
      }
  }
}

export default getGridItemSize
