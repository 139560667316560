import ListItemIcon from './list-item-icon/ListItemIcon'
import { IStrapiIconPosition, IStrapiListIconColor, IStrapiListItem, IStrapiListStyle } from '../../types/strapi'
import ListItemContent from './list-item-content/ListItemContent'
import { Stack } from '@mui/material'

export interface IListItemProps {
  data: IStrapiListItem
  listStyle: IStrapiListStyle
  iconColor: IStrapiListIconColor
  iconPosition?: IStrapiIconPosition
  index: number
}

const ListItem = (props: IListItemProps) => {
  const { data, listStyle, iconColor, iconPosition, index } = props

  const direction = iconPosition === IStrapiIconPosition.TOP || iconPosition === IStrapiIconPosition.TOP_CENTERED ? 'column' : 'row'
  const alignItems = iconPosition === IStrapiIconPosition.TOP_CENTERED || iconPosition === IStrapiIconPosition.LEFT_CENTERED ? 'center' : 'flex-start'

  return (
    <Stack spacing={4} direction={direction} alignItems={alignItems}>
      <ListItemIcon listStyle={listStyle} iconColor={iconColor} index={index} />
      <ListItemContent {...data} listStyle={listStyle} />
    </Stack>
  )
}

export default ListItem
