import { Accordion, AccordionDetails } from '@mui/material'
import { IStrapiColor } from '../../../../types/strapi'
import renderSection, { ISectionData } from '../../../../utils/renderSection'
import BlockLayout from '../../../../layouts/block-layout/BlockLayout'
import { TabTitle } from '../TabsSection.styled'

export interface IMobileTabsProps {
  tabsGrouped: Record<string, Array<string>>
  sections: Array<ISectionData>
  activeTab: string
  indicatorColor: IStrapiColor
  onChange: (_: React.SyntheticEvent, tabLabel: string) => void
}

const MobileTabs = (props: IMobileTabsProps) => {
  const { tabsGrouped, sections, activeTab, indicatorColor, onChange } = props

  return (
    <>
      {Object.entries(tabsGrouped).map(([ tabLabel, tabSectionsIds ]) => (
        <Accordion
          key={tabLabel}
          expanded={activeTab === tabLabel}
          onChange={(event) => onChange(event, tabLabel)}
        >
          <TabTitle activeColor={indicatorColor}>
            {tabLabel}
          </TabTitle>

          <AccordionDetails>
            {tabSectionsIds.map(sectionId => {
              const section = sections.find(section => section.options.anchor === sectionId)

              if (!section) {
                return null
              }

              return (
                <BlockLayout
                  key={section.options.anchor}
                  options={section.options}
                  id={section.options.anchor}
                >
                  {renderSection(section)}
                </BlockLayout>
              )
            })}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}

export default MobileTabs
