import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { IStrapiIconPosition, IStrapiListIconColor, IStrapiListItem, IStrapiListStyle, IStrapiSection, IStrapiTitle } from '../../../types/strapi'
import ListItem from '../../list-item/ListItem'
import getGridItemSize from '../../../utils/getGridItemSize'
import ListItemIcon from '../../list-item/list-item-icon/ListItemIcon'
import ListItemContent from '../../list-item/list-item-content/ListItemContent'
import Title from '../../title/Title'

export interface IListSectionData extends IStrapiSection {
  title?: IStrapiTitle
  description?: string
  listStyle: IStrapiListStyle
  iconColor: IStrapiListIconColor
  iconPosition?: IStrapiIconPosition
  items: Array<IStrapiListItem>
  itemsInRow: number
}

export interface IListSectionProps {
  data: IListSectionData
}

const ListSection: React.FC<IListSectionProps> = ({ data }) => {
  const { title, description, listStyle, iconColor: iconColorUnsafe, items, itemsInRow, iconPosition } = data

  const iconColor = iconColorUnsafe || 'primary'

  const addSpacing = title || description

  return (
    <>
      <Title title={title} hasSpacing />

      {description && (
        <Typography fontSize={20} color='text.secondary' mb={2} sx={{ maxWidth: 900 }}>
          {description}
        </Typography>
      )}

      {itemsInRow > 0 ? (
        <Grid container spacing={4} component='ul' pl={0} {...addSpacing && { pt: { xs: 2, md: 4 } }}>
          {items.map((item, index) => (
            <Grid item key={index} {...getGridItemSize(itemsInRow)} component='li' sx={{ listStyle: 'none' }}>
              <ListItem listStyle={listStyle} iconColor={iconColor} iconPosition={iconPosition} data={item} index={index} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          component='ul'
          rowSpacing={{ xs: 2, sm: 3, md: 4 }}
          columnSpacing={4}
          pl={0}
          pt={{ xs: 2, lg: 4 }}
          sx={{ listStyle: 'none' }}
        >
          {items.map((item, index) => (
            <Grid item key={index} component='li' display='flex' sx={{ mt: 0 }}>
              <ListItemContent {...item} listStyle={listStyle} />
              {index !== (items.length - 1) && (
                <ListItemIcon listStyle={listStyle} iconColor={iconColor} index={index} sx={{ ml: 4 }} />
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}

export default ListSection
