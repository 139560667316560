import React from 'react'
import { IStrapiImage } from '../../../types/strapi'
import Image from '../../image'
import { SlideImageWrapper } from './ImageSlide.styled'

export interface IImageSlideProps extends JSX.IntrinsicAttributes {
  image: IStrapiImage
}

const ImageSlide: React.FC<IImageSlideProps> = ({ image }) => {
  return (
    <SlideImageWrapper>
      <Image image={image} />
    </SlideImageWrapper>
  )
}

export default ImageSlide
