import React, { useLayoutEffect, useRef } from 'react'
import { IStrapiImage, IStrapiSection, IStrapiWysiwyg } from '../../../types/strapi'
import Image from '../../image'
import { Grid } from '@mui/material'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { SectionWrapper } from './HoveringSection.styled'
import BackgroundImage from '../../background-image/BackgroundImage'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { useSectionsContext } from '../../../hooks/useSectionsContext'

export interface IHoveringSectionData extends IStrapiSection {
  image: IStrapiImage
  content: IStrapiWysiwyg
}

export interface IHoveringSectionProps {
  data: IHoveringSectionData
}

const HoveringSection: React.FC<IHoveringSectionProps> = ({ data }) => {
  const { image, content, options } = data

  const { setNextSectionModifiers, setPrevSectionModifiers } = useSectionsContext()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobile('md')

  useLayoutEffect(() => {
    const element = wrapperRef.current

    if (!element) {
      return
    }

    const wrapperPadding = isMobile ? 40 : 48

    const observer = new ResizeObserver((entries) => {
      const elementHeight = entries[0].contentRect.height
      setNextSectionModifiers({ extraTopPadding: wrapperPadding + elementHeight / 2 }, data)
      setPrevSectionModifiers({ extraBottomPadding: wrapperPadding + elementHeight / 2 }, data)
    })

    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [ data, isMobile, setNextSectionModifiers, setPrevSectionModifiers ])

  return (
    <SectionWrapper ref={wrapperRef} sx={{ px: { xs: 3, md: 12 }, py: 12 }}>
      <BackgroundImage {...options.background} sx={{ zIndex: -1 }} />

      <Grid container alignItems='center' spacing={{ xs: 8, md: 14, lg: 20 }}>
        <Grid item xs={8} md={4} mx='auto'>
          <Image image={image} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Wysiwyg data={content} />
        </Grid>
      </Grid>
    </SectionWrapper>
  )
}

export default HoveringSection
