import React from 'react'
import LicenseCard, { ILicenseCardProps } from '../../../section-card/license-card/LicenseCard'
import CardsSection, { ICardsSectionData } from '..'

export interface ILicenseCardsSectionData extends ICardsSectionData {
  licenseCards: Array<ILicenseCardProps>
}

export interface ILicenseCardsSectionProps {
  data: ILicenseCardsSectionData
}

const LicenseCardsSection: React.FC<ILicenseCardsSectionProps> = ({ data }) => {
  const { licenseCards } = data

  return (
    <CardsSection CardComponent={LicenseCard} data={data} cards={licenseCards} />
  )
}

export default LicenseCardsSection
