import React from 'react'
import { IStrapiCase, IStrapiIndustry, IStrapiService } from '../../types/strapi'

export interface ICasesContext {
  cases: Array<IStrapiCase>
  industries: Array<IStrapiIndustry>
  services: Array<IStrapiService>
}

export const CasesContext = React.createContext<ICasesContext>({
  cases: [],
  industries: [],
  services: [],
})

export interface ICasesProviderProps extends React.PropsWithChildren {
  context: ICasesContext
}

export const CasesProvider = ({ context, children }: ICasesProviderProps) => {

  return (
    <CasesContext.Provider value={context}>
      {children}
    </CasesContext.Provider>
  )
}
