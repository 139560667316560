import React from 'react'
import { CardWrapper } from '../SectionCard.styled'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { IStrapiActionCard } from '../../../types/strapi'
import { useIsMobile } from '../../../hooks/useIsMobile'
import ChevronDownIcon from '../../../icons/ChevronDown'

export interface IAccordionCardProps extends IStrapiActionCard {}

const AccordionCard: React.FC<IAccordionCardProps> = (props) => {
  const { content, details, backgroundColor } = props

  const isMobile = useIsMobile('md')
  const hasDetails = Boolean(details.data?.childMarkdownRemark.fields.fixedHtml)

  const renderBody = () => {
    if (!hasDetails) {
      return <Wysiwyg data={content} />
    }

    if (!isMobile) {
      return (
        <>
          <Wysiwyg data={content} />
          <Wysiwyg data={details} sx={{ mt: 4 }} />
        </>
      )
    }

    return (
      <Accordion sx={{ alignSelf: 'stretch' }}>
        <AccordionSummary sx={{ 'p': 0, 'm': 0, '& .MuiAccordionSummary-content': { my: 0, mr: 4 } }} expandIcon={<ChevronDownIcon color='inherit' />}>
          <Wysiwyg data={content} />
        </AccordionSummary>

        <AccordionDetails sx={{ my: 2 }}>
          <Wysiwyg data={details} />
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <CardWrapper background={{ color: backgroundColor }}>
      {renderBody()}
    </CardWrapper>
  )
}

export default AccordionCard
