import { IStrapiStepItem } from '../../../../types/strapi'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { RowLine, RowNumberWrapper, VerticalLine } from './RowNumberedSteps.styled'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import Wysiwyg from '../../../wysiwyg/Wysiwyg'

export interface IRowNumberedStepsProps {
  steps: Array<IStrapiStepItem>
}

const RowNumberedSteps = (props: IRowNumberedStepsProps) => {
  const { steps } = props

  const columns = steps.length

  const isMobile = useIsMobile('md')

  return (
    <Grid container columns={columns} spacing={isMobile ? 8 : 0}>
      {steps.map((step, index) => (
        <Grid item key={index} sm={columns} md={1} display='flex' flexDirection={isMobile ? 'row' : 'column'} alignItems='center'>
          <Stack direction={isMobile ? 'column' : 'row'} alignItems='center' alignSelf='stretch'>
            {!isMobile && <RowLine />}

            <RowNumberWrapper sx={{ mb: isMobile ? 0 : 5 }}>
              <Typography variant='body2' fontWeight={700}>
                {index + 1}
              </Typography>

              {isMobile && <VerticalLine sx={{ visibility: index !== steps.length - 1 ? 'visible' : 'hidden' }} />}
            </RowNumberWrapper>

            {!isMobile && <RowLine />}
          </Stack>

          <Box px={4} alignSelf='center'>
            <Wysiwyg data={step.content} sx={{ textAlign: isMobile ? 'left' : 'center' }} />
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export default RowNumberedSteps
