import { Grid } from '@mui/material'
import { IStrapiPriceCard } from '../../../../../types/strapi'
import PriceCard from '../../../../section-card/price-card/PriceCard'
import { useState } from 'react'
import Button from '../../../../button'

export interface IDesktopPriceCardsProps {
  priceCards: Array<IStrapiPriceCard>
}

const DesktopPriceCards = (props: IDesktopPriceCardsProps) => {
  const { priceCards } = props

  const [ isCollapsed, setCollapsed ] = useState(true)

  return (
    <>
      <Grid container spacing={4} flexWrap='nowrap'>
        {priceCards.map((card, index) => (
          <Grid item key={index} xs>
            <PriceCard {...card} collapsed={isCollapsed} />
          </Grid>
        ))}
      </Grid>

      {isCollapsed && (
        <Button
          onClick={() => setCollapsed(false)}
          sx={{ display: 'flex', mt: 3, mb: 3, mx: 'auto' }}
          variant='outlined'
          size='large'
          label='See all features'
        />
      )}
    </>
  )
}

export default DesktopPriceCards
