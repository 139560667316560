import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ContentWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
}))

export const SlideContainer = styled(Box)(() => ({
  maxWidth: '100%',
  height: '100%',
  minHeight: 250,
  position: 'relative',
}))
