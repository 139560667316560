import React from 'react'
import { CardWrapper, CardContent } from '../SectionCard.styled'
import { IStrapiReview } from '../../../types/strapi'
import Image from '../../image'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { AccordionTitle } from './ReviewCard.styled'
import { Stack } from '@mui/material'

export interface IReviewCardProps extends IStrapiReview {}

const ReviewCard: React.FC<IReviewCardProps> = (props) => {
  const { title, review, reviewMore, author, authorDescription, image } = props

  return (
    <CardWrapper>
      <CardContent p={{ xs: 0, md: 2 }}>
        {title && (
          <Typography variant='subtitle1' component='h3' pb={{ xs: 2, md: 3, lg: 4 }}>{title}</Typography>
        )}

        <Typography variant='body1' color='text.secondary'>{review}</Typography>

        {reviewMore && (
          <Accordion>
            <AccordionTitle>
              <Typography fontWeight={600} variant='body2'>Read more</Typography>

            </AccordionTitle>
            <AccordionDetails>
              <Typography variant='body1' color='text.secondary'>{reviewMore}</Typography>
            </AccordionDetails>
          </Accordion>
        )}

        {(image || author || authorDescription) && (
          <Stack direction='row' alignItems='center' spacing={4} mt='auto' pt={6}>
            <Image image={image} sx={{ maxHeight: 48, maxWidth: '40%' }} />
            <div>
              {author && (
                <Typography variant='body2' color='text.primary' fontWeight={400}>{author}</Typography>
              )}
              {authorDescription && (
                <Typography variant='body2' color='brand.charcoalGray'>{authorDescription}</Typography>
              )}
            </div>
          </Stack>
        )}
      </CardContent>
    </CardWrapper>
  )
}

export default ReviewCard
