import { useState } from 'react'
import { IStrapiColor, IStrapiSection, IStrapiTabItem } from '../../../types/strapi'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { TabButton } from '../tabs-section/TabsSection.styled'
import { DotIcon } from '../../list-item/list-item-icon/ListItemIcon.styled'

export interface ITabsListSectionData extends IStrapiSection {
  tabItems: Array<IStrapiTabItem>
}

export interface ITabsListSectionProps {
  data: ITabsListSectionData
}

const TabsListSection = (props: ITabsListSectionProps) => {
  const { tabItems } = props.data

  const [ activeTab, setActiveTab ] = useState<string>(tabItems[0].name)

  const tabs = tabItems.map(({ name, values }) => ({ name, values: values.split(`\n`).map(t => t.trim()).filter(t => !!t) }))

  return (
    <>
      <Grid
        container
        spacing={{ xs: 4, md: 5, lg: 6, xl: 8 }}
        role='tablist'
        aria-label='technologies tabs'
      >
        {tabs.map(({ name }, index) => (
          <Grid item key={name}>
            <TabButton
              color='tertiary'
              variant={activeTab === name ? 'contained' : 'outlined'}
              activeColor={IStrapiColor.TEXT}
              onClick={() => setActiveTab(name)}
              role='tab'
              aria-selected={activeTab === name}
              aria-controls={'tabpanel-' + index}
              id={'tab-' + index}
            >
              {name}
            </TabButton>
          </Grid>
        ))}
      </Grid>

      <Box flexGrow='1' mt={{ xs: 6, md: 8, lg: 10, xl: 12 }}>
        {tabs.map(({ name, values }, index) => (
          <Box
            key={name}
            role='tabpanel'
            aria-labelledby={'tab-' + index}
            hidden={activeTab !== name}
            id={'tabpanel-' + index}
          >
            <Stack
              direction='row'
              flexWrap='wrap'
              component='ul'
              sx={{
                listStyle: 'none',
                paddingLeft: 0,
                margin: 0,
              }}
            >
              {activeTab === name && values.map((tech, index) => (
                <Box key={index} component='li' display='flex' alignItems='center' mb={4}>
                  <Typography color='text.secondary' fontWeight={500} variant='body1'>
                    {tech}
                  </Typography>
                  {index !== (values.length - 1) && (
                    <DotIcon color='primary' sx={{ mx: 4 }} />
                  )}
                </Box>
              ))}
            </Stack>
          </Box>
        ))}
      </Box>
    </>
  )
}

export default TabsListSection
