import React from 'react'
import { IStrapiCardStyle, IStrapiLicenseCard } from '../../../../types/strapi'
import CarouselSection, { ICarouselSectionData } from '..'
import CardStyleProvider from '../../../../contexts/card-style-provider/CardStyleProvider'
import LicenseCard from '../../../section-card/license-card/LicenseCard'

export interface ILicensesCarouselSectionData extends ICarouselSectionData {
  licenses: Array<IStrapiLicenseCard>
  cardStyle: IStrapiCardStyle
}

export interface ILicensesCarouselSectionProps {
  data: ILicensesCarouselSectionData
}

const LicensesCarouselSection: React.FC<ILicensesCarouselSectionProps> = ({ data }) => {
  const { licenses, cardStyle } = data

  return (
    <CardStyleProvider cardStyle={cardStyle}>
      <CarouselSection data={data} SlideComponent={LicenseCard} slides={licenses} />
    </CardStyleProvider>
  )
}

export default LicensesCarouselSection
