import { IStrapiStepItem } from '../../types/strapi'
import Image from '../image'
import { Box, SxProps } from '@mui/material'
import { StepIndex, StepWrapper } from './StepItem.styled'
import { useIsMobile } from '../../hooks/useIsMobile'
import Wysiwyg from '../wysiwyg/Wysiwyg'

export interface IStepItemProps extends IStrapiStepItem {
  step: number
  color?: 'nft' | 'primary'
  sx?: SxProps
}

const StepItem = (props: IStepItemProps) => {
  const { step, image, content, color, sx } = props

  const isMobile = useIsMobile('md')

  return (
    <StepWrapper isMobile={isMobile} py={isMobile ? 0 : 4} sx={isMobile ? sx : { pl: step % 2 ? 8 : 0, pr: step % 2 ? 0 : 8, ...sx }}>
      <StepIndex color={color} sx={{ order: isMobile ? 0 : step % 2, transform: isMobile ? 'none' : step % 2 ? 'translateX(50%)' : 'translateX(-50%)' }}>
        {step}
      </StepIndex>

      {!isMobile && (
        <Image image={image} sx={{ minWidth: '35%', maxHeight: 140 }} />
      )}

      <Box ml={{ xs: 4, lg: 8 }} flexGrow={1}>
        <Wysiwyg data={content} />
      </Box>

    </StepWrapper>
  )
}

export default StepItem
