import { CardWrapper } from '../SectionCard.styled'
import Wysiwyg from '../../wysiwyg/Wysiwyg'
import { IStrapiCardAlignment, IStrapiPriceLabelCard, IStrapiPriceLabelCardsTemplate } from '../../../types/strapi'
import { CardLabel, CardPrice, PriceCardHeader } from './PriceLabelCard.styled'
import Button from '../../button'
import { useCardStyleContext } from '../../../hooks/useCardStyleContext'

export interface IPriceLabelCardProps extends IStrapiPriceLabelCard {
  template?: IStrapiPriceLabelCardsTemplate
}

const PriceLabelCard = (props: IPriceLabelCardProps) => {
  const { content, header, price, label, button, template } = props

  const { alignment } = useCardStyleContext()

  return (
    <CardWrapper>
      {label && (
        <CardLabel>{label}</CardLabel>
      )}

      <PriceCardHeader template={template}>
        <Wysiwyg data={header} sx={{ ...(alignment === IStrapiCardAlignment.CENTER && { textAlign: 'center' }) }} />
      </PriceCardHeader>

      <CardPrice>{price}</CardPrice>

      <Wysiwyg
        data={content}
        sx={{
          ...(alignment === IStrapiCardAlignment.CENTER && { textAlign: 'center' }),
          '& ul': {
            listStyle: 'none',
            paddingLeft: 0,
          },
          '& li:not(:first-of-type):before': {
            content: '""',
            display: 'block',
            borderTop: '1px solid',
            borderTopColor: '#545D8D',
            marginLeft: 'calc((100% - 8%) / 2)',
            marginRight: 'calc((100% - 8%) / 2)',
            marginTop: 3,
            marginBottom: 3,
          },
          'paddingBottom': 6,
        }}
      />

      <Button {...button} sx={{ mt: 'auto' }} />
    </CardWrapper>
  )
}

export default PriceLabelCard
