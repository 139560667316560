import Grid from '@mui/material/Grid'
import { IStrapiIconListItem, IStrapiIconSize } from '../../../../types/strapi'
import IconListItem from '../../../icon-list-item/IconListItem'
import getGridItemSize from '../../../../utils/getGridItemSize'

export interface IDefaultListProps {
  listItems: Array<IStrapiIconListItem>
  itemsInRow: number
  iconSize?: IStrapiIconSize
}

const DefaultList = (props: IDefaultListProps) => {
  const { listItems, itemsInRow, iconSize } = props

  return (
    <Grid container spacing={6} my={{ xs: 0, md: 2, lg: 4 }}>
      {listItems.map((item, index) => (
        <Grid item key={index} {...getGridItemSize(itemsInRow)} flexGrow={1}>
          <IconListItem {...item} iconSize={iconSize} />
        </Grid>
      ))}
    </Grid>
  )
}

export default DefaultList
