import { GridProps } from '@mui/material/Grid'
import { IStrapiGridItemSize } from '../types/strapi'

export interface IGridSectionItemProps {
  xs: GridProps['xs']
  md: GridProps['md']
  lg: GridProps['lg']
}

const transformStrapiGridSize = (size: IStrapiGridItemSize): IGridSectionItemProps => {
  switch (size) {
    case IStrapiGridItemSize.SIZE_25:
      return { xs: 12, md: 4, lg: 3 }

    case IStrapiGridItemSize.SIZE_33:
      return { xs: 12, md: 4, lg: 4 }

    case IStrapiGridItemSize.SIZE_40:
      return { xs: 12, md: 5, lg: 5 }

    case IStrapiGridItemSize.SIZE_50:
      return { xs: 12, md: 6, lg: 6 }

    case IStrapiGridItemSize.SIZE_60:
      return { xs: 12, md: 7, lg: 7 }

    case IStrapiGridItemSize.SIZE_66:
      return { xs: 12, md: 8, lg: 8 }

    case IStrapiGridItemSize.SIZE_75:
      return { xs: 12, md: 8, lg: 9 }

    case IStrapiGridItemSize.SIZE_100:
      return { xs: 12, md: 12, lg: 12 }

    case IStrapiGridItemSize.AUTO:
      return { xs: false, md: false, lg: false }

    default:
      return { xs: 12, md: false, lg: false }
  }
}

export default transformStrapiGridSize
