import React from 'react'
import { IStrapiImageCard } from '../../../../types/strapi'
import ImageCard from '../../../section-card/image-card/ImageCard'
import CardsSection, { ICardsSectionData } from '..'

export interface IImageCardsSectionData extends ICardsSectionData {
  imageCards: Array<IStrapiImageCard>
}

export interface IImageCardsSectionProps {
  data: IImageCardsSectionData
}

const ImageCardsSection: React.FC<IImageCardsSectionProps> = ({ data }) => {
  const { imageCards } = data

  return (
    <CardsSection CardComponent={ImageCard} data={data} cards={imageCards} />
  )
}

export default ImageCardsSection
