import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

interface IStepWrapperProps {
  isMobile: boolean
}

export const StepWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'isMobile' })<IStepWrapperProps>(({ theme, isMobile }) => ({
  display: 'flex',
  width: isMobile ? '100%' : '50%',
  alignItems: isMobile ? 'flex-start' : 'center',
  boxSizing: 'border-box',

  ...(!isMobile && {
    background: theme.palette.brand.lightGray,
    boxShadow: '10px 10px 40px 0px rgba(0, 0, 0, 0.10), 4px 4px 26px 0px rgba(255, 255, 255, 0.25) inset, 0px 4px 2px 0px rgba(255, 255, 255, 0.25) inset, 0px -4px 2px 0px rgba(0, 0, 0, 0.05) inset',
    borderRadius: 5,
  }),
}))

export const StepIndex = styled('div', { shouldForwardProp: prop => prop !== 'color' })<{color?: string}>(({ theme, color }) => {
  const isNft = color === 'nft'

  return{
    background: isNft ? theme.palette.brand.nftText : theme.palette.brand.primaryGradient,
    color: theme.palette.common.white,
    fontSize: 24,
    fontWeight: 400,
    borderRadius: '50%',
    flexShrink: 0,
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('lg')]: {
      width: 50,
      height: 50,
      fontSize: 20,
    },

    [theme.breakpoints.down('md')]: {
      width: 40,
      height: 40,
      fontSize: 16,
    },
  }
})
