import Grid from '@mui/material/Grid'
import { IStrapiIconListItem, IStrapiIconSize } from '../../../../types/strapi'
import IconListItem from '../../../icon-list-item/IconListItem'
import { useIsMobile } from '../../../../hooks/useIsMobile'

export interface IInterleavedRowListProps {
  listItems: Array<IStrapiIconListItem>
  itemsInRow: number
  iconSize?: IStrapiIconSize
}

const InterleavedRowList = (props: IInterleavedRowListProps) => {
  const { listItems, itemsInRow, iconSize } = props

  const isMobile = useIsMobile('md')

  return (
    <Grid container rowSpacing={4} columnSpacing={{ xs: 4, md: 8, lg: 12, xl: 20 }} my={{ xs: 0, md: 2, lg: 4 }}>
      {listItems.map((item, index) => (
        <Grid item key={index} sm={false} md={(listItems.length > 1 && itemsInRow > 1) ? 6 : 12} lg={Math.round(12 / itemsInRow)} flexGrow={1}>
          <IconListItem
            {...item}
            iconSize={iconSize}
            direction={isMobile ? 'column' : index % 2 ? 'column' : 'column-reverse'}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default InterleavedRowList
