import { IStrapiCardAlignment } from '../types/strapi'

const transformStrapiAlignment = (alignment: IStrapiCardAlignment) => {
  switch (alignment) {
    case IStrapiCardAlignment.CENTER:
      return 'center'

    case IStrapiCardAlignment.LEFT:
      return 'start'

    default:
      return 'start'
  }
}

export default transformStrapiAlignment
