import React from 'react'
import { IStrapiSection, IStrapiWysiwyg } from '../../../types/strapi'
import Wysiwyg from '../../wysiwyg/Wysiwyg'

export interface IWysiwygSectionData extends IStrapiSection {
  wysiwyg: IStrapiWysiwyg
}

export interface IWysiwygSectionProps {
  data: IWysiwygSectionData
}

const WysiwygSection: React.FC<IWysiwygSectionProps> = ({ data }) => {
  const { wysiwyg } = data

  return (
    <Wysiwyg data={wysiwyg} />
  )
}

export default WysiwygSection
