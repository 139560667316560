import React from 'react'
import { IStrapiListIconColor, IStrapiListStyle } from '../../../types/strapi'
import { CheckIcon, DashIcon, DotIcon, DotsSquareIcon, FilledCheckIcon, UpperlineIcon, ListItemIconWrapper, StepIcon } from './ListItemIcon.styled'
import { SxProps } from '@mui/material'

export interface IListItemIconProps {
  listStyle: IStrapiListStyle
  iconColor: IStrapiListIconColor
  index: number
  sx?: SxProps
}

const ListItemIcon: React.FC<IListItemIconProps> = (props) => {
  const { listStyle, iconColor, index, sx } = props

  switch (listStyle) {
    case IStrapiListStyle.NONE:
    case IStrapiListStyle.LINK:
      return null

    case IStrapiListStyle.CHECK:
      return (
        <ListItemIconWrapper sx={sx}>
          <CheckIcon color={iconColor} />
        </ListItemIconWrapper>
      )

    case IStrapiListStyle.DASH:
      return (
        <ListItemIconWrapper sx={sx}>
          <DashIcon color={iconColor} />
        </ListItemIconWrapper>
      )

    case IStrapiListStyle.FILLED_CHECK:
      return (
        <ListItemIconWrapper sx={sx}>
          <FilledCheckIcon color={iconColor} />
        </ListItemIconWrapper>
      )

    case IStrapiListStyle.UPPERLINE:
      return <UpperlineIcon color={iconColor} />

    case IStrapiListStyle.STEP:
      return (
        <ListItemIconWrapper sx={sx}>
          <StepIcon>{`${('00' + (index + 1)).slice(-2)}`}</StepIcon>
        </ListItemIconWrapper>
      )

    case IStrapiListStyle.DOTS_SQUARE:
      return (
        <ListItemIconWrapper sx={sx}>
          <DotsSquareIcon color={iconColor} />
        </ListItemIconWrapper>
      )

    case IStrapiListStyle.DISC:
      return (
        <ListItemIconWrapper sx={sx}>
          <DotIcon color={iconColor} />
        </ListItemIconWrapper>
      )

    default: {
      // eslint-disable-next-line no-console
      console.log('Unknown list style', listStyle)
      return null
    }
  }
}

export default ListItemIcon
