import { IStrapiSection, IStrapiStepItem, IStrapiTitle, IStrapiStepsTemplate } from '../../../types/strapi'
import Title from '../../title/Title'
import CardArrowSteps from './card-arrow-steps/CardArrowSteps'
import ColumnNumberedSteps from './column-numbered-steps/ColumnNumberedSteps'
import RowNumberedSteps from './row-numbered-steps/RowNumberedSteps'

export interface IStepsSectionData extends IStrapiSection {
  title: IStrapiTitle
  stepItems: Array<IStrapiStepItem>
  template: IStrapiStepsTemplate
}

export interface IStepsSectionProps {
  data: IStepsSectionData
}

const StepsSection = (props: IStepsSectionProps) => {
  const { title, stepItems, template } = props.data

  const renderSteps = () => {
    switch(template) {
      case IStrapiStepsTemplate.COLUMN_NUMBERED:
        return <ColumnNumberedSteps steps={stepItems} color='primary' />

      case IStrapiStepsTemplate.NFT_STEPS:
        return <ColumnNumberedSteps steps={stepItems} color='nft' />

      case IStrapiStepsTemplate.ROW_NUMBERED:
        return <RowNumberedSteps steps={stepItems} />

      case IStrapiStepsTemplate.CARD_ARROWS:
        return <CardArrowSteps steps={stepItems} />

      default:
        // eslint-disable-next-line no-console
        console.log('Unknown steps template', template)
        return <ColumnNumberedSteps steps={stepItems} />
    }
  }

  return (
    <>
      <Title title={title} hasSpacing={Boolean(stepItems?.length)} />

      {renderSteps()}
    </>
  )
}

export default StepsSection
