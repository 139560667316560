import React from 'react'
import { IStrapiTextCard } from '../../../../types/strapi'
import TextCard from '../../../section-card/text-card/TextCard'
import CardsSection, { ICardsSectionData } from '..'

export interface ITextCardsSectionData extends ICardsSectionData {
  textCards: Array<IStrapiTextCard>
}

export interface ITextCardsSectionProps {
  data: ITextCardsSectionData
}

const TextCardsSection: React.FC<ITextCardsSectionProps> = ({ data }) => {
  const { textCards } = data

  return (
    <CardsSection CardComponent={TextCard} data={data} cards={textCards} />
  )
}

export default TextCardsSection
