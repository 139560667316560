import { IStrapiCase } from '../../../../types/strapi'
import { Button, Stack } from '@mui/material'
import CaseCardFull from '../../../case-card-full/CaseCardFull'
import { useState } from 'react'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import LoadingIcon from '../../../../icons/Loading'

export interface ICasesSelectionProps {
  cases: Array<IStrapiCase>
  casesPerPage?: number
}

export const CasesSelection = (props: ICasesSelectionProps) => {
  const { cases, casesPerPage = 10 } = props

  const [ limit, setLimit ] = useState(casesPerPage)

  const isMobile = useIsMobile('md')

  const casesLimited = cases.filter((c, index) => index < limit)

  const hasMore = limit < cases.length

  const handleLoadMore = () => {
    setLimit(prev => prev + casesPerPage)
  }

  return (
    <Stack spacing={5}>
      {casesLimited.map((caseData, index) => (
        <CaseCardFull key={index} {...caseData} />
      ))}

      {hasMore && (
        <Button
          color='primary'
          variant='text'
          sx={{ alignSelf: 'center', px: 14 }}
          onClick={handleLoadMore}
        >
          <Stack direction={isMobile ? 'row' : 'column'} alignItems='center' spacing={4}>
            <LoadingIcon color='primary' sx={{ height: 24, width: 24 }} />
            <span>Load more</span>
          </Stack>
        </Button>
      )}
    </Stack>
  )
}
