import { graphql, useStaticQuery } from 'gatsby'

interface ISiteResponse {
  site: {
    siteMetadata: {
      title: string
      description: string
      siteUrl: string
    }
    buildTime: string
  }
}

const useSEO = () => {
  const { site } = useStaticQuery<ISiteResponse>(graphql`
    query {
      site {
        buildTime
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  return {
    siteMetadata: site.siteMetadata,
    buildTime: site.buildTime,
  }
}

export default useSEO
