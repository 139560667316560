import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'
import RightArrow from '../../icons/RightArrow'

export const CaseCardWrapper = styled(Card)(({ theme }) => ({
  'position': 'relative',
  'height': '100%',
  'maxWidth': '100%',
  'borderRadius': 10,
  'color': theme.palette.common.white,
  'display': 'flex',
  'flexDirection': 'column',
  'minHeight': 250,
  'transition': 'background-color ease-in-out .3s, color ease-in-out .3s',

  '& .hover-container': {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  ' & .normal-box': {
    position: 'absolute',
    width: '100%',
    left: 0,
    top: 0,
    opacity: 1,
  },

  '& .hover-box': {
    opacity: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  '&:hover': {
    'boxShadow': theme.shadows[3],
    'backgroundColor': theme.palette.brand.lightGray,
    'color': theme.palette.text.primary,

    '& .normal-box': {
      opacity: 0,
    },

    '& .hover-box': {
      opacity: 1,
    },
  },
}))

export const CardDescription = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  flexShrink: 0,
}))

export const CardTechnologies = styled(Typography)(({ theme }) => ({
  color: theme.palette.brand.brandedRed,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  flexShrink: 0,
  marginTop: 'auto',
  whiteSpace: 'pre-wrap',
}))

export const ArrowIcon = styled(RightArrow)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  bottom: -4,
  color: theme.palette.brand.brandedRed,
}))
