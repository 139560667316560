import { IStrapiStepItem } from '../../../../types/strapi'
import StepItem from '../../../step-item/StepItem'
import { Accordion, AccordionDetails, Stack } from '@mui/material'
import { useState } from 'react'
import Button from '../../../button'
import { CollapsedBlanked } from './ColumnNumberedSteps.styled'

const expandLabel = 'Discover the whole process'
const collapseLabel = 'Hide the process'

export interface IColumnNumberedStepsProps {
  steps: Array<IStrapiStepItem>
  color?: 'nft' | 'primary'
}

const ColumnNumberedSteps = (props: IColumnNumberedStepsProps) => {
  const { steps, color = 'primary' } = props

  const isNft = color === 'nft'

  const [ expanded, setExpanded ] = useState(false)

  const handleExpand = () => {
    setExpanded(true)
  }

  const handleCollapse = () => {
    setExpanded(false)
  }

  const firstSteps = (steps || []).slice(0, 2)
  const restSteps = (steps || []).slice(2)

  return (
    <>
      <Stack spacing={6} pt={2}>
        {firstSteps.map((stepItem, index) => (
          <StepItem
            key={index}
            step={index + 1}
            color={color}
            {...stepItem}
            sx={{
              alignSelf: index % 2 ? 'flex-end' : 'flex-start',
            }}
          />
        ))}
      </Stack>

      {!expanded && (
        <>
          <CollapsedBlanked />
          <Button
            color={color}
            variant='outlined'
            size={isNft ? 'large' : 'medium'}
            label={isNft ? expandLabel.toUpperCase() : expandLabel}
            sx={{ display: 'block', mx: 'auto', mt: 3 }}
            onClick={handleExpand}
          />
        </>
      )}

      <Accordion expanded={expanded} sx={{ mt: 6 }}>
        <div />
        <AccordionDetails>
          <Stack spacing={6}>
            {restSteps.map((stepItem, index) => (
              <StepItem
                key={index}
                color={color}
                step={index + 3}
                {...stepItem}
                sx={{
                  alignSelf: index % 2 ? 'flex-end' : 'flex-start',
                }}
              />
            ))}
          </Stack>

          <Button
            color={color}
            variant='outlined'
            size={isNft ? 'large' : 'medium'}
            label={isNft ? collapseLabel.toUpperCase() : collapseLabel}
            sx={{ display: 'flex', mt: 6, mb: 3, mx: 'auto' }}
            onClick={handleCollapse}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default ColumnNumberedSteps
