import React from 'react'
import { IStrapiImageCard, IStrapiImagesCarouselTemplate } from '../../../../types/strapi'
import ImageSlide from '../../../carousel-slide/image-slide/ImageSlide'
import CarouselSection, { ICarouselSectionData } from '..'
import ImageGrayscaleCard from '../../../section-card/image-grayscale-card/ImageGrayscaleCard'

export interface IImagesCarouselSectionData extends ICarouselSectionData {
  slides: Array<IStrapiImageCard>
  template: IStrapiImagesCarouselTemplate
}

export interface IImagesCarouselSectionProps {
  data: IImagesCarouselSectionData
}

const ImagesCarouselSection: React.FC<IImagesCarouselSectionProps> = ({ data }) => {
  const { slides, template } = data

  const castImageSlideComponent = () => {
    switch (template) {
      case IStrapiImagesCarouselTemplate.DEFAULT:
        return ImageSlide

      case IStrapiImagesCarouselTemplate.CARD_GRAYSCALE:
        return ImageGrayscaleCard

      default:
        // eslint-disable-next-line no-console
        console.log('Unknown image carousel template:', template)
        return ImageSlide
    }
  }

  return (
    <CarouselSection
      data={data}
      SlideComponent={castImageSlideComponent()}
      slides={slides}
      hideArrows={template === IStrapiImagesCarouselTemplate.CARD_GRAYSCALE}
    />
  )
}

export default ImagesCarouselSection
