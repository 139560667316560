import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CaseCardFullWrapper = styled(Box)(({ theme }) => ({
  'backgroundColor': theme.palette.brand.lightGray,
  'borderRadius': 10,
  'transition': 'box-shadow ease-in-out .2s',

  '&:hover': {
    'boxShadow': theme.shadows[3],
  },
}))

export const TagWrapper = styled(Box)(({ theme }) => ({
  padding: '8px 12px',
  backgroundColor: theme.palette.common.white,
  borderRadius: 8,
  fontWeight: 400,
  overflow: 'hidden',
}))

export const IndustryTag = styled(Typography)(() => ({
  fontWeight: 'inherit',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}))

export const ImageWrapper = styled(Box)(({ theme }) => ({
  borderRadius: 10,
  backgroundColor: theme.palette.brand.platinumGray,
  overflow: 'hidden',
}))
