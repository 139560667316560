import React from 'react'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { IStrapiSimpleLink, IStrapiSection } from '../../../types/strapi'
import Link from '../../link'
import { Stack, Typography } from '@mui/material'

export interface IBreadCrumbsSectionData extends IStrapiSection {
  links: Array<IStrapiSimpleLink>
}

export interface IBreadCrumbsSectionProps {
  data: IBreadCrumbsSectionData
}

const BreadCrumbsSection = (props: IBreadCrumbsSectionProps) => {
  const { links } = props.data

  const isMobile = useIsMobile('lg')

  return (
    <Stack direction='row' alignItems='center' spacing={1} {...isMobile && { display: 'none' }}>
      {links.map(({ label, link }, index) => (
        <React.Fragment key={index}>
          {Boolean(index) && (
            <Typography variant='body2' fontSize={15} color='text.primary'>{` » `}</Typography>
          )}
          {link ? (
            <Link {...link} sx={{ fontWeight: link ? 400 : 300, fontSize: 15, color: 'text.primary' }}>{label}</Link>
          ) : (
            <Typography variant='body2' fontSize={15} color='text.primary'>{label}</Typography>
          )}
        </React.Fragment>
      ))}
    </Stack>
  )
}

export default BreadCrumbsSection
