import React from 'react'
import { CardImageWrapper, ImageCard } from './ImageGrayscaleCard.styled'
import Image from '../../image'
import { IStrapiImageCard } from '../../../types/strapi'

export interface IImageGrayscaleCardProps extends IStrapiImageCard {}

const ImageGrayscaleCard = (props: IImageGrayscaleCardProps) => {

  return (

    <CardImageWrapper>
      <ImageCard>
        <Image image={props.image} sx={{ maxHeight: 60 }} />
      </ImageCard>
    </CardImageWrapper>
  )
}

export default ImageGrayscaleCard
