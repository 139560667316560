import React from 'react'
import { CardWrapper, CardContent } from '../SectionCard.styled'
import { IStrapiImageCard } from '../../../types/strapi'
import Image from '../../image'

export interface IImageCardProps extends IStrapiImageCard {}

const ImageCard: React.FC<IImageCardProps> = (props) => {
  const { image } = props

  return (
    <CardWrapper>
      <CardContent sx={{ justifyContent: 'center', height: '100%' }}>
        <Image
          image={image}
          sx={{
            maxWidth: '80%',
            height: 48,
          }}
        />
      </CardContent>
    </CardWrapper>
  )
}

export default ImageCard
