import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const RightArrow: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' {...props}>
      <path d='M23.4461 12.4892H0.553831C0.281523 12.4892 0.0615234 12.2692 0.0615234 11.9969C0.0615234 11.7246 0.281523 11.5046 0.553831 11.5046H22.2569L19.2692 8.51692C19.0769 8.32462 19.0769 8.01231 19.2692 7.82C19.4615 7.62769 19.7738 7.62769 19.9661 7.82L23.7954 11.6492C23.9369 11.7908 23.9784 12.0015 23.9015 12.1862C23.8246 12.3692 23.6446 12.4892 23.4461 12.4892Z' fill='currentColor' />
      <path d='M19.6123 16.3231C19.4861 16.3231 19.36 16.2754 19.2646 16.1785C19.0723 15.9862 19.0723 15.6738 19.2646 15.4815L23.0984 11.6477C23.2907 11.4554 23.6031 11.4554 23.7954 11.6477C23.9877 11.84 23.9877 12.1523 23.7954 12.3446L19.9615 16.1785C19.8646 16.2754 19.7384 16.3231 19.6123 16.3231Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default RightArrow
