import { GridProps } from '@mui/material/Grid'
import { IStrapiImageSize } from '../types/strapi'

export interface IGridSize {
  xs: GridProps['xs']
  md: GridProps['md']
}

const getImageGridSize = (size: IStrapiImageSize): IGridSize => {
  switch (size) {
    case IStrapiImageSize.EXTRA_LARGE:
      return { xs: 12, md: 7 }

    case IStrapiImageSize.LARGE:
      return { xs: 12, md: 6 }

    case IStrapiImageSize.NORMAL:
      return { xs: 12, md: 6 }

    case IStrapiImageSize.SMALL:
      return { xs: 12, md: 5 }

    default:
      return { xs: 12, md: 6 }
  }
}

export default getImageGridSize
