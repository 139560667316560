import { styled } from '@mui/material/styles'

export const SocialLink = styled('a')(({ theme }) => ({
  'color': theme.palette.common.white,
  'padding': theme.spacing(2),
  'borderRadius': 5,
  'display': 'inline-flex',
  'backgroundColor': theme.palette.custom?.purpleBlue,
  '& + &': {
    marginLeft: theme.spacing(2),
  },

  '& svg': {
    fontSize: 20,
  },
}))

export const EmailAddressLink = styled('a')(({ theme }) => ({
  marginBottom: theme.spacing(6.5),
  fontWeight: 600,
  fontStyle: 'normal',
  display: 'inline-block',
  color: theme.palette.text.primary,
  textDecoration: 'none',
}))
