import React from 'react'
import { IStrapiCase } from '../../../../types/strapi'
import CarouselSection, { ICarouselSectionData } from '..'
import CaseSlide from '../../../carousel-slide/case-slide/CaseSlide'

export interface ICasesCarouselSectionData extends ICarouselSectionData {
  cases: Array<IStrapiCase>
}

export interface ICasesCarouselSectionProps {
  data: ICasesCarouselSectionData
}

const CasesCarouselSection: React.FC<ICasesCarouselSectionProps> = ({ data }) => {
  const { cases } = data

  return (
    <CarouselSection data={data} SlideComponent={CaseSlide} slides={cases} />
  )
}

export default CasesCarouselSection
