import React, { useCallback, useState } from 'react'
import { IStrapiColor, IStrapiSection, IStrapiTabElement, IStrapiTabIndicator, IStrapiTabsPosition } from '../../../types/strapi'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { useSectionsContext } from '../../../hooks/useSectionsContext'
import MobileTabs from './mobile-tabs/MobileTabs'
import DesktopTabs from './desktop-tabs/DesktopTabs'

export interface ITabsSectionData extends IStrapiSection {
  tabsPosition: IStrapiTabsPosition
  indicatorColor: IStrapiColor
  indicator: IStrapiTabIndicator
  tabs: Array<IStrapiTabElement>
}

export interface ITabsSectionProps {
  data: ITabsSectionData
}

const TabsSection: React.FC<ITabsSectionProps> = ({ data }) => {
  const { tabs = [], tabsPosition, indicatorColor, indicator } = data

  const tabsGrouped = tabs.reduce((grouped: Record<string, Array<string>>, tab: IStrapiTabElement) => ({ ...grouped, [tab.label]: (grouped[tab.label] || []).concat(tab.sectionId) }), {})
  const [activeTab, setActiveTab] = useState(tabs[0].label)

  const { sections } = useSectionsContext()
  const tabsIdsSet = new Set(tabs.map(tab => tab.sectionId))
  const tabsSections = sections?.filter(section => section.options.anchor && tabsIdsSet.has(section.options.anchor))

  const isMobile = useIsMobile('lg')

  const handleSelectTab = useCallback((_: React.SyntheticEvent, tabLabel: string) => {
    setActiveTab(tabLabel)
  }, [])

  if (isMobile) {
    return (
      <MobileTabs
        activeTab={activeTab}
        sections={tabsSections}
        indicatorColor={indicatorColor}
        onChange={handleSelectTab}
        tabsGrouped={tabsGrouped}
      />
    )
  }

  return (
    <DesktopTabs
      sections={tabsSections}
      activeTab={activeTab}
      onChange={handleSelectTab}
      indicator={indicator}
      indicatorColor={indicatorColor}
      tabsPosition={tabsPosition}
      tabsGrouped={tabsGrouped}
    />
  )
}

export default TabsSection
