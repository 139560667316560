import { ICardsSectionData } from '..'
import { IStrapiDesktopAlignment, IStrapiMobileAlignment, IStrapiSimpleCard } from '../../../../types/strapi'
import SimpleCard from '../../../section-card/simple-card/SimpleCard'
import { Grid } from '@mui/material'
import CardStyleProvider from '../../../../contexts/card-style-provider/CardStyleProvider'
import Title from '../../../title/Title'
import BlockLayout from '../../../../layouts/block-layout/BlockLayout'
import renderSection from '../../../../utils/renderSection'
import getGridItemSize from '../../../../utils/getGridItemSize'
import { useSectionsContext } from '../../../../hooks/useSectionsContext'

export interface ISimpleCardsSectionData extends ICardsSectionData {
  simpleCards: Array<IStrapiSimpleCard>
  customCardId?: string
  desktopAlignment: IStrapiDesktopAlignment
  mobileAlignment: IStrapiMobileAlignment
}

export interface ISimpleCardsSectionProps {
  data: ISimpleCardsSectionData
}

const SimpleCardsSection = ({ data }: ISimpleCardsSectionProps) => {
  const { title, simpleCards, customCardId, cardStyle, cardsInRow, ...rest } = data

  return (
    <CardStyleProvider cardStyle={cardStyle}>
      <Title title={title} hasSpacing />

      <Grid container spacing={6} pt={{ xs: 0, md: 2, lg: 4 }}>
        {customCardId && (
          <Grid item {...getGridItemSize(cardsInRow)} key='custom' flexGrow={1}>
            <CustomCard cardId={customCardId} />
          </Grid>
        )}

        {simpleCards.map((card, index) => (
          <Grid item {...getGridItemSize(cardsInRow)} key={index} flexGrow={1}>
            <SimpleCard {...card} {...rest} />
          </Grid>
        ))}
      </Grid>
    </CardStyleProvider>
  )
}

export default SimpleCardsSection

export const CustomCard = (props: {cardId: string}) => {
  const { cardId } = props

  const { sections } = useSectionsContext()
  const customSections = sections.filter(section => cardId && section.options.anchor === cardId)

  return (
    <>
      {customSections.map((section, index) => (
        <BlockLayout key={`${section.options.anchor}${index}`} options={section.options}>
          {renderSection(section)}
        </BlockLayout>
      ))}
    </>
  )
}
