import { styled } from '@mui/material/styles'
import { AccordionSummary, Button, Tab, Tabs, Theme, alpha } from '@mui/material'
import { IStrapiColor } from '../../../types/strapi'

interface ITabButtonProps {
  activeColor: IStrapiColor
}

export const TabButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'activeColor' })<ITabButtonProps>(
  ({ theme, activeColor, variant }) => ({
    ...(activeColor === IStrapiColor.TEXT && {
      'backgroundColor': 'transparent',
      'color': theme.palette.text.primary,
      'border': `1px solid ${theme.palette.text.primary}`,

      '&:hover': {
        backgroundColor: alpha(theme.palette.text.primary, 0.1),
        border: `1px solid ${theme.palette.text.primary}`,
      },

      ...(variant === 'contained' && {
        'backgroundColor': alpha(theme.palette.text.primary, 0.3),

        '&:hover': {
          backgroundColor: alpha(theme.palette.text.primary, 0.4),
        },
      }),
    }),
  }),
)

export const StyledTabs = styled(Tabs, { shouldForwardProp: (prop) => prop !== 'activeColor' })<ITabButtonProps>(({ theme, activeColor }) => ({
  'marginLeft': -25,

  '& .MuiTabs-indicator': {
    backgroundColor: castTabColor(theme, activeColor),
  },
}))

export const TabTitle = styled(AccordionSummary, { shouldForwardProp: (prop) => prop !== 'activeColor' })<ITabButtonProps>(({ theme, activeColor }) => ({
  'fontSize': 20,
  'fontWeight': 600,

  '&.Mui-expanded': {
    color: castTabColor(theme, activeColor),
  },
}))

export const StyledTab = styled(Tab, { shouldForwardProp: (prop) => prop !== 'activeColor' })<ITabButtonProps>(({ theme, activeColor }) => ({
  'flexGrow': 0,
  'fontWeight': 600,
  'color': theme.palette.text.primary,

  '&.Mui-selected': {
    color: castTabColor(theme, activeColor),
  },
}))

const castTabColor = (theme: Theme, color?: IStrapiColor) => {
  switch (color) {
    case IStrapiColor.PRIMARY:
      return theme.palette.primary.main

    case IStrapiColor.SECONDARY:
      return theme.palette.secondary.main

    case IStrapiColor.TERTIARY:
      return theme.palette.tertiary.main

    case IStrapiColor.TEXT:
      return theme.palette.text.secondary

    default:
      // eslint-disable-next-line no-console
      console.log('Unknown tab color prop', color)
      return theme.palette.primary.main
  }
}
