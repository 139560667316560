import { Stack } from '@mui/material'
import { IStrapiSection, IStrapiTitle } from '../../../types/strapi'
import ToggleButtonFilter from './toggle-button-filter/ToggleButtonFilter'
import { useContext } from 'react'
import { CasesContext } from '../../../contexts/cases-context-provider/CasesProvider'
import { CasesSelection } from './cases-selection/CasesSelection'
import Title from '../../title/Title'

export interface ICasesSelectionSectionData extends IStrapiSection {
  title?: IStrapiTitle
  casesPerPage: number
}

export interface ICasesSelectionSectionProps {
  data: ICasesSelectionSectionData
}

const CasesSelectionSection = ({ data }: ICasesSelectionSectionProps) => {
  const { title, casesPerPage = 10 } = data

  const { cases, services, industries } = useContext(CasesContext)

  return (
    <>
      <Title title={title} hasSpacing />

      <Stack spacing={2} direction='row' my={6}>
        <ToggleButtonFilter options={services} title='Services' />
        <ToggleButtonFilter options={industries} title='Industries' />
      </Stack>

      <CasesSelection cases={cases} casesPerPage={casesPerPage} />
    </>
  )
}

export default CasesSelectionSection
