import ChevronDownIcon from '../../../../icons/ChevronDown'
import Link from '../../../link'
import { ClickAwayListener, Container, Grid } from '@mui/material'
import { FilterButton, FilterMenu, OptionButton } from './ToggleButtonFilter.styled'
import { usePopupState, bindPopper, bindToggle } from 'material-ui-popup-state/hooks'
import { useEffect, useState } from 'react'
import { compareUrls } from '../../../../utils/compareUrls'
import { isClient } from '../../../../utils/isClient'

interface IToggleButtonFilterProps {
  options: Array<{
    name: string
    filterUrl: string
  }>
  title: string
}

export const ToggleButtonFilter = (props: IToggleButtonFilterProps) => {
  const { options, title } = props

  const popupState = usePopupState({ variant: 'popper', popupId: `filter-${title}` })
  const [ pathname, setPathname ] = useState<string>()

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    event.preventDefault()
    popupState.close()
  }

  const isSelected = options.some(({ filterUrl }) => compareUrls(filterUrl, pathname))

  useEffect(() => {
    if (isClient()) {
      setPathname(window.location.pathname)
    }
  }, [ options ])

  return (
    <>
      <FilterButton
        variant='text'
        endIcon={<ChevronDownIcon transform={popupState.isOpen ? 'rotate(180)' : undefined} />}
        disableRipple
        color={isSelected ? 'primary' : 'inherit'}
        {...bindToggle(popupState)}
      >
        {title}
      </FilterButton>

      <FilterMenu
        {...bindPopper(popupState)}
        onContextMenu={undefined}
        modifiers={[
          { name: 'flip', enabled: false },
        ]}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Container>
            <Grid container spacing={{ xs: 3, md: 4 }} py={8}>
              {options.map(({ filterUrl, name }, index) => (
                <Grid item key={index}>
                  <OptionButton
                    LinkComponent={Link}
                    href={compareUrls(filterUrl, pathname) ? '/cases/' : filterUrl}
                    variant='contained'
                    color='tertiary'
                    disableRipple
                    selected={compareUrls(filterUrl, pathname)}
                  >
                    {name}
                  </OptionButton>
                </Grid>
              ))}
            </Grid>
          </Container>
        </ClickAwayListener>
      </FilterMenu>
    </>
  )
}

export default ToggleButtonFilter
