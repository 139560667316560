import React from 'react'
import { IStrapiPriceLabelCard } from '../../../../types/strapi'
import CardsSection, { ICardsSectionData } from '..'
import PriceLabelCard from '../../../section-card/price-label-card/PriceLabelCard'

export interface IPriceLabelCardsSectionData extends ICardsSectionData {
  priceLabelCards: Array<IStrapiPriceLabelCard>
}

export interface IPriceLabelCardsSectionProps {
  data: IPriceLabelCardsSectionData
}

const PriceLabelCardsSection: React.FC<IPriceLabelCardsSectionProps> = ({ data }) => {
  const { priceLabelCards } = data

  return (
    <CardsSection CardComponent={PriceLabelCard} data={data} cards={priceLabelCards} />
  )
}

export default PriceLabelCardsSection
